import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Modal } from '@mui/material';
import { useProject } from './ProjectContext';

const WelcomePopup = ({ open, onClose, triggeredByHowItWorks }) => {
    const { project } = useProject();
    const handleCloseAndDontShowAgain = () => {
        localStorage.setItem('showWelcomePopup', 'false');
        onClose();
    };

    return (
        <>
            {/* Welcome Popup */}
            <Modal open={open} onClose={onClose}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'black',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                    }}
                >
                    <img src='./LOGO-5.png' style={{ width: '400px', paddingBottom: '16px'}} />
                    <br/>
                    <Typography variant="h6" component="h2" color='#f5f5f5'>
                        Welcome to the <strong>{project.project_name || 'Whoseland Mapping Platform'}</strong>
                    </Typography>
                    <Typography variant='body2' color='#f5f5f5' sx={{ mt: 2 }}>
                        Share stories related to Milwaukee places you know. You can also browse place-based stories added by other people.
                    </Typography>
                    <Typography variant='body2' color='#f5f5f5' sx={{ mt: 2 }}>
                        Set the timeline slider at top to a historical era.  Click on blue location dots for content related to that era.  Grey dots indicate locations with content from other eras.
                    </Typography>
                    <Typography variant='body2' color='#f5f5f5' sx={{ mt: 2 }}>
                        Click on “Add a Story” to add content to an existing place or to add a new place of your own.
                    </Typography>
                    <br/>
                    <Typography variant='body2' color='#f5f5f5' sx={{ mt: 2, fontStyle: 'italic' }}>
                        This map was created by the Whose Land? team in partnership with UW-Milwaukee and funding from Wisconsin Humanities and the Whiting Foundation.
                    </Typography>
                    <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
                        <Button variant="contained" onClick={onClose}>
                            Get Started
                        </Button>
                        {/*{!triggeredByHowItWorks && (
                            <Button variant="contained" onClick={handleCloseAndDontShowAgain}>
                                Close and don't show again
                            </Button>
                        )}*/}
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default WelcomePopup;
