// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
    getAuth,
    GoogleAuthProvider,
    signInWithPopup,
    signOut,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    updateProfile
} from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDm-yCGlVaqS8ZIuK4fRMuCBm3f_PQrr6A",
    authDomain: "whoseland-maps.firebaseapp.com",
    projectId: "whoseland-maps",
    storageBucket: "whoseland-maps.appspot.com",
    messagingSenderId: "289452614466",
    appId: "1:289452614466:web:2f8c38f38032fe240ddedb",
    measurementId: "G-S718R9SKXP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
// Initialize Firebase Auth
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

// Function to sign in with Google
const signInWithGoogle = async () => {
    try {
        const result = await signInWithPopup(auth, googleProvider);
        const user = result.user;
        return user; // return the user object
    } catch (error) {
        console.error(error);
        throw error; // throw error to handle in the calling function
    }
};

// Email/Password Auth
const registerWithEmail = async (email, password, firstName, lastName) => {
    const auth = getAuth();
    try {
        // Register the user
        const result = await createUserWithEmailAndPassword(auth, email, password);

        // Update profile with first and last name
        await updateProfile(result.user, {
            displayName: `${firstName} ${lastName}`,
        });

        return result.user; // Return the user if successful
    } catch (error) {
        // Log the error for debugging
        console.error("Error registering user: ", error);

        // Throw custom error message for specific Firebase errors
        switch (error.code) {
            case 'auth/email-already-in-use':
                throw new Error('This email address is already registered.');
            case 'auth/weak-password':
                throw new Error('The password is too weak. Please choose a stronger password.');
            case 'auth/invalid-email':
                throw new Error('Invalid email address.');
            default:
                throw new Error('Failed to register. Please try again.');
        }
    }
};

const signInWithEmail = async (email, password) => {
    const auth = getAuth();
    try {
        const result = await signInWithEmailAndPassword(auth, email, password);
        return result.user; // Return the user if successful
    } catch (error) {
        // Log the error for debugging
        console.error("Error logging in: ", error);

        // Handle different Firebase auth errors with custom messages
        switch (error.code) {
            case 'auth/wrong-password':
                throw new Error('Incorrect password. Please try again.');
            case 'auth/user-not-found':
                throw new Error('No user found with this email address.');
            case 'auth/invalid-email':
                throw new Error('Invalid email address.');
            default:
                throw new Error('Failed to sign in. Please try again.');
        }
    }
};

// Logout function
const logout = async () => {
    await signOut(auth);
};

export { auth, signInWithGoogle, registerWithEmail, signInWithEmail, logout };