import React, { useState, useEffect } from 'react';
import {
    Grid,
    TextField,
    Button,
    Paper,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Typography
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useAuth } from './authContext';


// Main component to manage layers
const MapLayerAdmin = () => {
    const [layers, setLayers] = useState([]);  // Store layers from the database
    const [openDialog, setOpenDialog] = useState(false);  // For add/edit form
    const [openHelpDialog, setOpenHelpDialog] = useState(false);  // For Help modal
    const [editingLayer, setEditingLayer] = useState(null);  // Store layer being edited
    const [formData, setFormData] = useState({ name: '', url: '', city: '', max_zoom: 18, max_native_zoom: 16 });  // Form data
    const { user, globalRoles, projectRoles } = useAuth();

    useEffect(() => {
        fetchLayers();
    }, []);

    const fetchLayers = () => {
        fetch('https://mapsite.whoseland.work/api/manage_layers.php', {
            method: 'POST',
            body: new URLSearchParams({ action: 'getAll' }),
        })
            .then(response => response.json())
            .then(data => setLayers(data))
            .catch(error => console.error('Error fetching layers:', error));
    };

    // Open dialog for adding/editing a layer
    const handleOpenDialog = (layer = null) => {
        if (layer) {
            setEditingLayer(layer);
            setFormData({ ...layer });
        } else {
            setEditingLayer(null);
            setFormData({ name: '', url: '', city: '', max_zoom: 18, max_native_zoom: 16 });
        }
        setOpenDialog(true);
    };

    // Close dialog
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setFormData({ name: '', url: '', city: '', max_zoom: 18, max_native_zoom: 16 });
    };
    const handleOpenHelpDialog = () => {
        setOpenHelpDialog(true);
    };

    // Close Help dialog
    const handleCloseHelpDialog = () => {
        setOpenHelpDialog(false);
    };

    // Handle form input change
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    // Add or update layer
    const handleSubmit = () => {
        const action = editingLayer ? 'edit' : 'add';
        const formDataToSend = new FormData();
        formDataToSend.append('action', action);
        if (editingLayer) {
            formDataToSend.append('id', editingLayer.id);
        }
        formDataToSend.append('name', formData.name);
        formDataToSend.append('url', formData.url);
        formDataToSend.append('projectid', formData.projectid);
        formDataToSend.append('max_zoom', formData.max_zoom);
        formDataToSend.append('max_native_zoom', formData.max_native_zoom);
        formDataToSend.append('added_by', user.uid);  // Change as needed

        fetch('https://mapsite.whoseland.work/api/manage_layers.php', {
            method: 'POST',
            body: formDataToSend,
        })
            .then(response => response.json())
            .then(result => {
                console.log(result);
                fetchLayers();  // Refresh layer list
                handleCloseDialog();
            })
            .catch(error => console.error('Error saving layer:', error));
    };

    // Delete layer
    const handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this layer?')) {
            fetch('https://mapsite.whoseland.work/api/manage_layers.php', {
                method: 'POST',
                body: new URLSearchParams({ action: 'delete', id }),
            })
                .then(response => response.json())
                .then(result => {
                    console.log(result);
                    fetchLayers();  // Refresh layer list
                })
                .catch(error => console.error('Error deleting layer:', error));
        }
    };

    return (
        <Grid container spacing={2} sx={{ p: 4 }}>
            <Grid item xs={12}>
                <Typography variant='h4'>Map Layers Admin</Typography>
                <Typography variant='subtitle2'>Use this tool to add/edit/delete Map Layers from the map.</Typography>
                <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={() => handleOpenDialog()}>
                    Add Layer
                </Button>
                <Button variant="contained" color="secondary" sx={{ mt: 2, ml: 2 }} onClick={handleOpenHelpDialog}>
                    Help
                </Button>
            </Grid>

            {/* Table to list all layers */}
            <Grid item xs={12}>
                <TableContainer component={Paper} sx={{ p: 1, mt: 2 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>URL</TableCell>
                                <TableCell>ProjectID</TableCell>
                                <TableCell>Max Zoom</TableCell>
                                <TableCell>Max Native Zoom</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {layers.map((layer) => (
                                <TableRow key={layer.id}>
                                    <TableCell>{layer.name}</TableCell>
                                    <TableCell>{layer.url}</TableCell>
                                    <TableCell>{layer.projectid}</TableCell>
                                    <TableCell>{layer.max_zoom}</TableCell>
                                    <TableCell>{layer.max_native_zoom}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleOpenDialog(layer)}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton onClick={() => handleDelete(layer.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            <Dialog open={openHelpDialog} onClose={handleCloseHelpDialog}>
                <DialogTitle>Help</DialogTitle>
                <DialogContent>
                    <Typography>
                        Map Layers can be added using the ADD LAYER button. Fill in a name for the new layer and the layer URL.
                        URLs should follow this format:
                        {"https://hostwebsite.tld/maps/tile/xxxxx/{z}/{x}/{y}.png"} — Where 'hostwebsite.tld' is the website hosting
                        the map layer, e.g., mapwarper.net.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseHelpDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>{editingLayer ? 'Edit Layer' : 'Add Layer'}</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Layer Name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="URL"
                        name="url"
                        value={formData.url}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="ProjectID"
                        name="projectid"
                        value={formData.projectid}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Max Zoom"
                        name="max_zoom"
                        value={formData.max_zoom}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        type="number"
                    />
                    <TextField
                        label="Max Native Zoom"
                        name="max_native_zoom"
                        value={formData.max_native_zoom}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        type="number"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} variant="contained" color="primary">
                        {editingLayer ? 'Update Layer' : 'Add Layer'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

export default MapLayerAdmin;
