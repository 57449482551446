import React, { useState, useEffect } from 'react';
import {
    Grid,
    TextField,
    Button,
    Paper,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Typography
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

// Main component to manage comments
const CommentsAdmin = () => {
    const [comments, setComments] = useState([]);  // Store comments from the database
    const [openDialog, setOpenDialog] = useState(false);  // For add/edit form
    const [editingComment, setEditingComment] = useState(null);  // Store comment being edited
    const [formData, setFormData] = useState({ first_name: '', last_name: '', email: '', comment: '' });  // Form data

    useEffect(() => {
        fetchComments();
    }, []);

    // Fetch all comments
    const fetchComments = () => {
        fetch('https://mapsite.whoseland.work/api/manage_comments.php', {
            method: 'POST',
            body: new URLSearchParams({ action: 'getAdmin' }),
        })
            .then(response => response.text())  // Get the response as text first
            .then(data => {
                console.log('Response:', data);  // Log the full response
                try {
                    const jsonData = JSON.parse(data);  // Try to parse the JSON
                    const sortedComments = jsonData.sort((a, b) => new Date(b.updated_at || b.created_at) - new Date(a.updated_at || a.created_at));
                    setComments(sortedComments);
                } catch (error) {
                    console.error('Error parsing JSON:', error);
                }
            })
            .catch(error => console.error('Error fetching comments:', error));
    };

    // Open dialog for editing a comment
    const handleOpenDialog = (comment) => {
        setEditingComment(comment);
        setFormData({
            first_name: comment.first_name,
            last_name: comment.last_name,
            email: comment.email,
            comment: comment.comment,
        });
        setOpenDialog(true);
    };

    // Close dialog
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setFormData({ first_name: '', last_name: '', email: '', comment: '' });
    };

    // Handle form input change
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    // Update comment
    const handleSubmit = () => {
        const formDataToSend = new FormData();
        formDataToSend.append('action', 'edit');
        formDataToSend.append('id', editingComment.id);
        formDataToSend.append('first_name', formData.first_name);
        formDataToSend.append('last_name', formData.last_name);
        formDataToSend.append('email', formData.email);
        formDataToSend.append('comment', formData.comment);

        fetch('https://mapsite.whoseland.work/api/manage_comments.php', {
            method: 'POST',
            body: formDataToSend,
        })
            .then(response => response.json())
            .then(result => {
                console.log(result);
                fetchComments();  // Refresh comments list
                handleCloseDialog();
            })
            .catch(error => console.error('Error saving comment:', error));
    };

    // Delete comment
    const handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this comment?')) {
            fetch('https://mapsite.whoseland.work/api/manage_comments.php', {
                method: 'POST',
                body: new URLSearchParams({ action: 'delete', id }),
            })
                .then(response => response.json())
                .then(result => {
                    console.log(result);
                    fetchComments();  // Refresh comments list
                })
                .catch(error => console.error('Error deleting comment:', error));
        }
    };

    return (
        <Grid container spacing={2} sx={{ p: 4 }}>
            {/* Table to list all comments */}
            <Grid item xs={12} spacing={2}>
                <Typography variant='h4'>Comment Admin</Typography>
                <Typography variant='subtitle2'>Use this component to view/moderate/edit/delete comments all in one place.</Typography>
                <Typography variant='subtitle2'>Comments can also be edited or deleted by Admin Accounts when viewing them in the side panel.</Typography>
                <TableContainer component={Paper} sx={{p: 1, mt: 4}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>First Name</TableCell>
                                <TableCell>Last Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Comment</TableCell>
                                <TableCell>Date Added/Edited</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {comments.map((comment) => (
                                <TableRow key={comment.id}>
                                    <TableCell>{comment.first_name}</TableCell>
                                    <TableCell>{comment.last_name}</TableCell>
                                    <TableCell>{comment.email}</TableCell>
                                    <TableCell>{comment.comment}</TableCell>
                                    <TableCell>{new Date(comment.updated_at || comment.created_at).toLocaleString()}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleOpenDialog(comment)}>
                                            <EditIcon/>
                                        </IconButton>
                                        <IconButton onClick={() => handleDelete(comment.id)}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            {/* Dialog for editing a comment */}
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Edit Comment</DialogTitle>
                <DialogContent>
                    <TextField
                        label="First Name"
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Last Name"
                        name="last_name"
                        value={formData.last_name}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Comment"
                        name="comment"
                        value={formData.comment}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        multiline
                        rows={4}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} variant="contained" color="primary">
                        Update Comment
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

export default CommentsAdmin;
