import React, { useEffect, useState } from 'react';
import {
    Box,
    Grid,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Paper,
    Slider,
    FormControlLabel,
    Checkbox,
    ListItemText, FormGroup
} from '@mui/material';
import { useUserPreferences } from './UserPreferencesContext';
import baseLayerOptions from './baseLayerOptions';
import IconButton from "@mui/material/IconButton";
import { Close } from "@mui/icons-material/";
import { GridCloseIcon } from "@mui/x-data-grid";
import axios from 'axios';

const Preferences = ({ setShowPreferences, overlayLayers, selectedLayers, setSelectedLayers }) => {
    const { preferences, setPreferences } = useUserPreferences();


    const handleBaseLayerChange = (e) => {
        const newPreferences = { ...preferences, baseLayer: e.target.value };
        setPreferences(newPreferences);
        localStorage.setItem('userPreferences', JSON.stringify(newPreferences));
    };
    const handleOverlayChange = (event, layer) => {
        const { checked } = event.target;

        // Add or remove layer from selectedLayers based on the checked status
        const updatedLayers = checked
            ? [...selectedLayers, layer]  // Add layer if checked
            : selectedLayers.filter(selected => selected.id !== layer.id);  // Remove layer if unchecked

        setSelectedLayers(updatedLayers);  // Update the state
    };


    return (
        <Paper elevation={3} sx={{ p: 2, mt: 2, backgroundColor: '#f5f5f5', borderRadius: '8px', height: '100%' }}>
            {/* Close button */}
            <Box sx={{ position: 'absolute', top: 20, right: 5, zIndex: 10000 }}>
                <IconButton>
                    <GridCloseIcon onClick={() => setShowPreferences(false)} />
                </IconButton>
            </Box>

            {/* Preferences Form */}
            <Box sx={{ p: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>Change Base Map</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="baseLayer-label">Choose base layer</InputLabel>
                            <Select
                                labelId="baseLayer-label"
                                id="baseLayer"
                                value={preferences.baseLayer || ''}
                                label="Choose base layer"
                                onChange={handleBaseLayerChange}
                            >
                                {baseLayerOptions && Object.keys(baseLayerOptions).map(layer => (
                                    <MenuItem key={layer} value={layer}>
                                        {layer.replace(/([A-Z])/g, ' $1').trim()}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* Overlay Layers */}
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom sx={{ mb: 3, mt: 3 }}>Add Historical Overlay</Typography>
                        <FormControl component="fieldset" fullWidth>
                            <FormGroup>
                                {overlayLayers.map((layer) => (
                                    <FormControlLabel
                                        key={layer.id}
                                        control={
                                            <Checkbox
                                                checked={selectedLayers.some(selected => selected.id === layer.id)}
                                                onChange={(event) => handleOverlayChange(event, layer)}  // Pass layer and event
                                                value={layer.id}  // Use layer.id for easier comparison
                                            />
                                        }
                                        label={layer.name || 'NOT AVAILABLE'}
                                        sx={{ mb: 3 }}
                                    />
                                ))}
                            </FormGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    );
};

export default Preferences;
