import React, { useState, useContext } from 'react';
import axios from 'axios';
import { useAuth } from './authContext';

const AdminBatchUpdate = () => {
    // Use context to check if the user is an admin
    const { globalRoles, projectRoles } = useAuth();

    // Local state to manage loading, error, and log messages
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [log, setLog] = useState([]);

    // Function to handle running the PHP script
    const handleRunScript = async () => {
        if (!globalRoles.isAdmin) {
            setError('You do not have permission to run this script.');
            return;
        }

        setLoading(true);
        setError(null);
        setLog([]);

        try {
            // Send a POST request to the PHP script
            const response = await axios.post('https://mapsite.whoseland.work/api/batchUpdateHasDataForYears.php', {
            });

            if (response.data.success) {
                setLog(response.data.message); // Display the verbose output
            } else {
                setError(response.data.error || 'An error occurred while running the script.');
            }
        } catch (err) {
            setError('Failed to connect to the server.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <br/>
            <h2>Batch Update Markers</h2>
            <br/>
            <h3>WARNING - This will take a significant amount of time</h3>
            <h3>DO NOT NAVIGATE AWAY FROM PAGE AFTER STARTED</h3>
            {!globalRoles.isAdmin && <p>You must be an admin to run this script.</p>}

            <br/>
            <br/>

            {globalRoles.isAdmin && (
                <div>
                    <button onClick={handleRunScript} disabled={loading}>
                        {loading ? 'Running...' : 'Run Batch Update'}
                    </button>
                </div>
            )}

            {loading &&
                <div>
                    <h3>Running script...</h3>
                    <br/>
                    <h3>DO NOT CLOSE PAGE UNTIL COMPLETE!</h3>
                </div>
            }

            {error && <p style={{color: 'red'}}>{error}</p>}

            {log.length > 0 && (
                <div>
                    <h3>Verbose Output</h3>
                    <ul>
                        {log.map((message, index) => (
                            <li key={index}>{message}</li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default AdminBatchUpdate;
