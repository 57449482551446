import React, { useState } from 'react';
import { signInWithGoogle, signInWithEmail, registerWithEmail } from './firebase';
import {
    Box,
    TextField,
    Button,
    Typography,
    Paper,
    Tabs,
    Tab,
} from '@mui/material';

const AuthForm = ({ onSuccess }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [isLogin, setIsLogin] = useState(true); // Toggle between Login and Register
    const [error, setError] = useState('');

    const handleGoogleSignIn = async () => {
        try {
            const user = await signInWithGoogle();
            alert(`Welcome, ${user.displayName || user.email}`);
            onSuccess();
        } catch (error) {
            console.error('Google Sign-In Error:', error);
            setError('Google sign-in failed. Please try again.');
        }
    };

    const handleEmailSubmit = async () => {
        setError(''); // Clear previous errors
        try {
            let user;
            if (isLogin) {
                user = await signInWithEmail(email, password);
                alert(`Welcome back, ${user.email}`);
                onSuccess();
            } else {
                user = await registerWithEmail(email, password, firstName, lastName);
                alert(`Registration successful. Welcome, ${user.email}`);
                onSuccess();
            }
        } catch (error) {
            console.error(isLogin ? 'Login Error' : 'Registration Error', error);
            setError(isLogin ? 'Login failed. Please check your credentials.' : 'Registration failed. Please try again.');
        }
    };

    const handleTabChange = (event, newValue) => {
        setIsLogin(newValue === 0);
    };

    return (
        <Paper elevation={3} sx={{ padding: 4, maxWidth: 400, margin: '0 auto', mt: 4 }}>
            <Typography variant="h6" align="center" gutterBottom>
                Welcome
            </Typography>

            <Tabs value={isLogin ? 0 : 1} onChange={handleTabChange} centered>
                <Tab label="Login" />
                <Tab label="Register" />
            </Tabs>

            <Box component="form" onSubmit={(e) => e.preventDefault()} sx={{ mt: 2 }}>
                {!isLogin && (
                    <>
                        <TextField
                            label="First Name"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            sx={{ marginBottom: 2 }}
                        />
                        <TextField
                            label="Last Name"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            sx={{ marginBottom: 2 }}
                        />
                    </>
                )}
                <TextField
                    label="Email"
                    type="email"
                    fullWidth
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{ marginBottom: 2 }}
                />
                <TextField
                    label="Password"
                    type="password"
                    fullWidth
                    variant="outlined"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    sx={{ marginBottom: 2 }}
                />

                {error && <Typography color="error" align="center">{error}</Typography>}

                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleEmailSubmit}
                    sx={{ marginBottom: 2 }}
                >
                    {isLogin ? 'Login' : 'Register'}
                </Button>

                <Typography align="center" variant="body2" sx={{ marginBottom: 2 }}>
                    or
                </Typography>

                <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={handleGoogleSignIn}
                >
                    Sign in with Google
                </Button>
            </Box>
        </Paper>
    );
};

export default AuthForm;
