const baseLayerOptions = {
    'JawgLight': {
        url: 'https://tile.jawg.io/jawg-light/{z}/{x}/{y}{r}.png?access-token=qLd8bVKCmNU0nymV0yHp7S52X2zfLe4c4XM3isuV6dWTOnP8tmsIoJPlRFN4pUji',
        attribution: '&copy; <a href="https://www.jawg.io" target="_blank">Jawg</a> - <a href="https://www.openstreetmap.org" target="_blank">&copy; OpenStreetMap</a> contributors',
        maxZoom: 22,
        maxNativeZoom: 20
    },
    'JawgDark': {
        url: 'https://tile.jawg.io/jawg-dark/{z}/{x}/{y}{r}.png?access-token=qLd8bVKCmNU0nymV0yHp7S52X2zfLe4c4XM3isuV6dWTOnP8tmsIoJPlRFN4pUji',
        attribution: '&copy; <a href="https://www.jawg.io" target="_blank">Jawg</a> - <a href="https://www.openstreetmap.org" target="_blank">&copy; OpenStreetMap</a> contributors',
        maxZoom: 22,
        maxNativeZoom: 20
    },
    'JawgSunny': {
        url: 'https://tile.jawg.io/jawg-sunny/{z}/{x}/{y}{r}.png?access-token=qLd8bVKCmNU0nymV0yHp7S52X2zfLe4c4XM3isuV6dWTOnP8tmsIoJPlRFN4pUji',
        attribution: '&copy; <a href="https://www.jawg.io" target="_blank">Jawg</a> - <a href="https://www.openstreetmap.org" target="_blank">&copy; OpenStreetMap</a> contributors',
        maxZoom: 22,
        maxNativeZoom: 20
    },
    'JawgStreet': {
        url: 'https://tile.jawg.io/jawg-streets/{z}/{x}/{y}{r}.png?access-token=qLd8bVKCmNU0nymV0yHp7S52X2zfLe4c4XM3isuV6dWTOnP8tmsIoJPlRFN4pUji',
        attribution: '&copy; <a href="https://www.jawg.io" target="_blank">Jawg</a> - <a href="https://www.openstreetmap.org" target="_blank">&copy; OpenStreetMap</a> contributors',
        maxZoom: 22,
        maxNativeZoom: 20
    },
    'JawgTerrain': {
        url: 'https://tile.jawg.io/jawg-terrain/{z}/{x}/{y}{r}.png?access-token=qLd8bVKCmNU0nymV0yHp7S52X2zfLe4c4XM3isuV6dWTOnP8tmsIoJPlRFN4pUji',
        attribution: '&copy; <a href="https://www.jawg.io" target="_blank">Jawg</a> - <a href="https://www.openstreetmap.org" target="_blank">&copy; OpenStreetMap</a> contributors',
        maxZoom: 22,
        maxNativeZoom: 20
    },
    'OpenStreetMapStreet': {
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        maxZoom: 19,
        subdomains: ['a', 'b', 'c']
    },
    'OpenStreetMapTerrain': {
        url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
        attribution: 'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (CC-BY-SA)',
        maxZoom: 17,
        subdomains: ['a', 'b', 'c']
    },
    'GoogleMapsStreet': {
        url: 'https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
        attribution: '&copy; <a href="https://www.google.com/maps">Google Maps</a>',
        maxZoom: 20,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
    },
    'GoogleMapsSatellite': {
        url: 'https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
        attribution: '&copy; <a href="https://www.google.com/maps">Google Maps</a>',
        maxZoom: 20,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
    }
};

export default baseLayerOptions;
