import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Button,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Select,
    Paper, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Grid
} from '@mui/material';
import axios from 'axios';

const UserRoleManager = () => {
    const [openUserDialog, setOpenUserDialog] = useState(false);
    const [openProjectDialog, setOpenProjectDialog] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedRole, setSelectedRole] = useState(null);
    const [roles, setRoles] = useState([]);
    const [users, setUsers] = useState([]);
    const [userData, setUserData] = useState([]);
    const [projects, setProjects] = useState([]);
    const [searchUser, setSearchUser] = useState('');
    const [searchProject, setSearchProject] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        // Fetch all users
        fetchUsers();

        // Fetch available projects
        fetchProjects();

        fetchUserData();

        fetchRoles();
    }, []);

    const fetchUsers = () => {
        axios.get('https://mapsite.whoseland.work/api/fetchUserU.php')
            .then(response => {
                setUsers(response.data.users);
            })
            .catch(error => console.error('Error fetching users:', error));
    };
    const fetchRoles = () => {
        axios.get('https://mapsite.whoseland.work/api/selectRoles.php')
            .then(response => {
                setRoles(response.data.roles);
            })
            .catch(error => console.error('Error fetching users:', error));
    };

    const fetchUserData = () => {
        axios.get('https://mapsite.whoseland.work/api/fetchUsers.php')
            .then(response => {
                setUserData(response.data.users);
            })
            .catch(error => console.error('Error fetching users:', error));
    };

    const fetchProjects = () => {
        axios.get('https://mapsite.whoseland.work/api/fetchProjects.php')
            .then(response => {
                setProjects(response.data.projects);
            })
            .catch(error => console.error('Error fetching projects:', error));
    };

    const handleSubmit = async () => {
        if (!selectedUser || !selectedProject || !selectedRole) {
            alert('Please select a user, project, and role.');
            return;
        }

        const data = {
            action: 'add',
            user_id: selectedUser.user_id,
            projectsid: selectedProject,
            role: selectedRole
        };

        try {
            await axios.post('https://mapsite.whoseland.work/api/updateUserRoles.php', data);
            alert('Role added successfully!');
        } catch (error) {
            alert('Error adding role.');
        }
    };

    const handleDelete = async (user) => {
        if (!selectedUser) {
            alert('Please select a user.');
            return;
        }

        const data = {
            action: 'remove',
            user_id: selectedUser.user_id,
            projectsid: user.projectsid,
            role: user.role_id
        };

        try {
            await axios.post('https://mapsite.whoseland.work/api/updateUserRoles.php', data);
            alert('Role removed successfully!');
        } catch (error) {
            alert('Error adding role.');
        }
    };

    const handleSelectUser = async (user) => {
        await setSelectedUser(null);
        setSelectedUser(user);

    };

    return (
        <Box>
            <Grid container spacing={2} padding={2} direction="row">
                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column' }}>
                    <Typography variant="h4" gutterBottom>User Role Management</Typography>
                </Grid>
                <Grid item xs={4} lg={4} sx={{ height: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column', }}>
                    <Typography variant="h6" gutterBottom>Select a User</Typography>
                    <TextField
                        value={searchUser}
                        onChange={(e) => setSearchUser(e.target.value)}
                        label="Search User"
                        fullWidth
                    />
                    <TableContainer component={Paper} >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Email</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users && users.length > 0 && users
                                    .filter(user => user.display_name.toLowerCase().includes(searchUser.toLowerCase())).map((user) => (
                                    <TableRow key={user.user_id} onClick={() => handleSelectUser(user)} hover>
                                        <TableCell>{user.display_name}</TableCell>
                                        <TableCell>{user.email}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={8} lg={8} padding={2} sx={{ height: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column', }}>
                    <TableContainer component={Paper} fullwidth>
                        <Table fullwidth>
                            <TableHead fullwidth>
                                <TableRow>
                                    <TableCell>User Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Project</TableCell>
                                    <TableCell>Role</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userData && userData.length > 0 && selectedUser && userData
                                    .filter(userData => userData.user_id === selectedUser.user_id)
                                    .map((userData) => (
                                        <TableRow key={userData.user_id}>
                                            <TableCell>{userData.display_name}</TableCell>
                                            <TableCell>{userData.email}</TableCell>
                                            <TableCell>{userData.project_name}</TableCell>
                                            <TableCell>{userData.role_name}</TableCell>
                                            <TableCell>
                                                {userData.role_name &&
                                                    <Button variant="contained" color="secondary" onClick={() => handleDelete(userData)} >
                                                        Remove Role
                                                    </Button>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Paper elevation={2} sx={{ height: 200, width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column' }}>
                        <Typography variant="h6" padding={1}>
                            Add A New User Role
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row'  }}>
                            <Select
                                value={selectedProject || ""} // The state variable that holds the selected project
                                onChange={(e) => setSelectedProject(e.target.value)} // Set the selected project on change
                                displayEmpty
                                sx={{ margin: 2 }}
                            >
                                <MenuItem value="" disabled>Select Project</MenuItem>
                                {projects && projects.length > 0 &&
                                    projects.map((project) => (
                                        <MenuItem key={project.projectsid} value={project.projectsid}>
                                            {project.project_name}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                            <Select
                                value={selectedRole || ""} // The state variable that holds the selected project
                                onChange={(e) => setSelectedRole(e.target.value)} // Set the selected project on change
                                displayEmpty
                                sx={{ margin: 2 }}
                            >
                                <MenuItem value="" disabled>Select Role</MenuItem>
                                {roles && roles.length > 0 &&
                                    roles.map((roles) => (
                                        <MenuItem key={roles.role_id} value={roles.role_id}>
                                            {roles.role_name}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', marginBottom: 2.5 }}>
                            <Button variant="contained" color="secondary" onClick={() => handleSubmit()}>ADD ROLE</Button>
                        </Box>

                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default UserRoleManager;
