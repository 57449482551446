import React, { useEffect, useState } from 'react';
import {
    TextField,
    Button,
    Box,
    Typography,
    Paper,
    List,
    ListItem,
    ListItemText,
    Dialog,
    DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import { useAuth } from './authContext'; // Assuming you have the authContext setup
import axios from 'axios';
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/joy";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { useProject } from './ProjectContext';


const CommentsSection = ({ idadd, year }) => {
    const { user, globalRoles, projectRoles } = useAuth();  // Access user info and admin status from authContext
    const [comments, setComments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [commentText, setCommentText] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [isUIDEditable, setIsUIDEditable] = useState(false);
    const [isIdaddEditable, setIsIdaddEditable] = useState(false);
    const [isYearEditable, setIsYearEditable] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [currentComment, setCurrentComment] = useState(null); // The comment being edited
    const [submitCompleted, setSubmitCompleted] = useState(false);
    const [editFirstName, setEditFirstName] = useState('');
    const [editLastName, setEditLastName] = useState('');
    const [editEmail, setEditEmail] = useState('');
    const [editCommentText, setEditCommentText] = useState('');
    const [editUID, setEditUID] = useState('');
    const [editIdadd, setEditIdadd] = useState('');
    const [editYear, setEditYear] = useState('');
    const { project } = useProject();


    // State for input values

    const [editableIdadd, setEditableIdadd] = useState(idadd );
    const [editableYear, setEditableYear] = useState(year);

    // Handlers for enabling edit mode
    const handleUIDClick = () => setIsUIDEditable(!isUIDEditable);
    const handleIdaddClick = () => setIsIdaddEditable(!isIdaddEditable);
    const handleYearClick = () => setIsYearEditable(!isYearEditable);

    const [expanded, setExpanded] = useState(false);

    const handleAccordionToggle = () => {
        setExpanded(!expanded);
    };

    const determineUID = () => {
        if (user) {
            return user.uid;  // Use Firebase UID if user is logged in
        } else {
            return `${lastName}_${email}`;  // Use firstName + lastName if not logged in
        }
    };
    const [uid, setUid] = useState(determineUID);

    // Fetch comments when idadd or year changes
    useEffect(() => {
        setLoading(true);
        const fetchComments = async () => {
            try {
                const formData = new FormData();
                formData.append('action', 'getAll');
                formData.append('idadd', idadd);
                formData.append('year', year);

                const response = await axios.post('https://mapsite.whoseland.work/api/manage_comments.php', formData);
                setComments(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching comments:', error);
                setLoading(false);
            }
        };

        fetchComments();
    }, [idadd, year, submitCompleted]);

    // Auto-populate name and email if user is logged in
    useEffect(() => {
        if (user) {
            setFirstName(user.displayName?.split(' ')[0] || '');
            setLastName(user.displayName?.split(' ')[1] || '');
            setEmail(user.email || '');
        }
    }, [user]);

    // Handle comment submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('action', 'create');
        formData.append('idadd', editableIdadd);
        formData.append('year', editableYear);
        formData.append('uid', uid);  //
        formData.append('first_name', firstName);
        formData.append('last_name', lastName);
        formData.append('email', email);  // New email field
        formData.append('comment', commentText);

        // Log form data before submitting
        console.log('Submitting comment with data:', {
            idadd,
            year,
            uid: user?.uid,
            first_name: firstName,
            last_name: lastName,
            email,
            comment: commentText,
        });

        try {
            const response = await axios.post('https://mapsite.whoseland.work/api/manage_comments.php', formData);
            console.log('Response from server:', response.data);  // Log the full server response

            if (response.data.message === 'Comment created successfully') {
                setCommentText('');
                alert('Comment added successfully!');
                setSubmitCompleted(!submitCompleted);
            } else {
                console.error('Error adding comment:', response.data);
                alert('Failed to add comment. Please check the console for details.');
            }
        } catch (error) {
            console.error('Error submitting comment:', error);  // Log the error in submitting
            alert('Error submitting comment. Please check the console for details.');
        }
    };
    const handleDelete = async (commentId) => {
        try {
            const formData = new FormData();
            formData.append('action', 'delete');
            formData.append('id', commentId);

            const response = await axios.post('https://mapsite.whoseland.work/api/manage_comments.php', formData);
            if (response.data.message === 'Comment deleted successfully') {
                setComments(comments.filter(comment => comment.id !== commentId));  // Remove deleted comment from state
            }
        } catch (error) {
            console.error('Error deleting comment:', error);
        }
    };

    // Function to handle editing a comment (placeholder for actual edit functionality)
    const handleEditClick = (comment) => {
        setCurrentComment(comment);
        setEditFirstName(comment.first_name);
        setEditLastName(comment.last_name);
        setEditEmail(comment.email);
        setEditCommentText(comment.comment);
        setOpenEditDialog(true);
        setEditUID(comment.uid);
        setEditIdadd(comment.idadd);
        setEditYear(comment.year);
    };

    // Function to handle submitting the edited comment
    const handleEditSubmit = async () => {
        try {
            const formData = new FormData();
            formData.append('action', 'edit');
            formData.append('id', currentComment.id); // Send the ID of the comment being edited
            formData.append('first_name', editFirstName);
            formData.append('last_name', editLastName);
            formData.append('email', editEmail);
            formData.append('comment', editCommentText);
            formData.append('uid', editUID);
            formData.append('idadd', editIdadd);
            formData.append('year', editYear);

            // Log form data before submitting
            console.log('Submitting edit with data:', {
                id: currentComment.id,
                first_name: editFirstName,
                last_name: editLastName,
                email: editEmail,
                comment: editCommentText,
                uid: currentComment.uid,
                idadd: currentComment.idadd,
                year: currentComment.year,
            });

            const response = await axios.post('https://mapsite.whoseland.work/api/manage_comments.php', formData);
            console.log('Response from server:', response.data);  // Log the server response

            if (response.data.message === 'Comment updated successfully') {
                // Update the comment in the state
                setComments(comments.map(comment =>
                    (comment.id === currentComment.id
                        ? { ...comment, first_name: editFirstName, last_name: editLastName, email: editEmail, comment: editCommentText }
                        : comment)
                ));
                setOpenEditDialog(false); // Close the dialog
            } else {
                console.error('Error updating comment:', response.data);
            }
        } catch (error) {
            console.error('Error submitting edited comment:', error);  // Log any errors
        }
    };

    const adminOrMod = () => {
        const projectAdminRole = project.projectid + '_Admin'; // Construct the specific project admin role

        return (
            globalRoles.isAdmin ||
            globalRoles.isDev ||
            (projectRoles && projectRoles.length > 0 && projectRoles.includes(projectAdminRole)) // Check if the user has a project-specific admin role
        );
    };

    return (
        <>
            <Box sx={{ mt: 4 }}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography variant="h6">
                            {loading ? 'Loading comments...' : `[${comments.length}] Comments`}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {/* Comments List */}
                        {loading ? (
                            <Typography>Loading comments...</Typography>
                        ) : (
                            <List>
                                {comments.map((comment) => (
                                    <ListItem key={comment.id} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Box flexGrow={1}>
                                            <ListItemText
                                                primary={`${comment.first_name} ${comment.last_name}`}
                                                secondary={`${comment.comment} (Posted on: ${new Date(comment.created_at).toLocaleDateString()})`}
                                            />
                                        </Box>

                                        {/* Conditionally render Edit/Delete buttons if the user is the author or an admin */}
                                        {(user?.uid === comment.uid || adminOrMod()) && (
                                            <Box>
                                                <IconButton onClick={() => handleEditClick(comment)} aria-label="edit">
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton onClick={() => handleDelete(comment.id)} aria-label="delete">
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Box>
                                        )}
                                    </ListItem>
                                ))}
                            </List>
                        )}
                    </AccordionDetails>
                </Accordion>

                <br/>

                {/* Comment Form */}
                <Accordion expanded={expanded} onChange={handleAccordionToggle}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="leave-comment-content"
                        id="leave-comment-header"
                    >
                        <Typography variant="h6">Leave a Comment</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Paper elevation={3} sx={{ p: 2 }}>
                            <form onSubmit={handleSubmit}>
                                {/* Only show First Name, Last Name, Email fields if no user is logged in */}
                                {!user && (
                                    <>
                                        <TextField
                                            label="First Name"
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                            fullWidth
                                            margin="normal"
                                        />
                                        <TextField
                                            label="Last Name"
                                            value={lastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                            fullWidth
                                            margin="normal"
                                        />
                                        <TextField
                                            label="Email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            fullWidth
                                            margin="normal"
                                        />
                                    </>
                                )}

                                {/* If user is logged in but not an admin, auto-fill but hide these fields */}
                                {user && !globalRoles.isAdmin && (
                                    <>
                                        <input type="hidden" value={firstName} />
                                        <input type="hidden" value={lastName} />
                                        <input type="hidden" value={email} />
                                    </>
                                )}

                                {/* If the user is an admin, show the fields but auto-populate them */}
                                {globalRoles.isAdmin && (
                                    <>
                                        <TextField
                                            label="First Name"
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                            fullWidth
                                            margin="normal"
                                            InputProps={{
                                                readOnly: false, // This field is editable by default
                                            }}
                                        />
                                        <TextField
                                            label="Last Name"
                                            value={lastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                            fullWidth
                                            margin="normal"
                                            InputProps={{
                                                readOnly: false, // This field is editable by default
                                            }}
                                        />
                                        <TextField
                                            label="Email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            fullWidth
                                            margin="normal"
                                            InputProps={{
                                                readOnly: false, // This field is editable by default
                                            }}
                                        />

                                        {/* UID Field - Greyed out by default, editable when clicking the pencil icon */}
                                        <Box display="flex" alignItems="center">
                                            <TextField
                                                label="UID"
                                                value={uid}
                                                onChange={(e) => setUid(e.target.value)}
                                                fullWidth
                                                margin="normal"
                                                InputProps={{
                                                    readOnly: !isUIDEditable,  // Greyed out unless toggled editable
                                                }}
                                                sx={{
                                                    input: {
                                                        color: isUIDEditable ? 'black' : 'grey',  // Change text color when editable
                                                    },
                                                }}
                                            />
                                            <IconButton onClick={handleUIDClick}>
                                                <EditIcon />
                                            </IconButton>
                                        </Box>

                                        {/* IDADD Field - Greyed out by default, editable when clicking the pencil icon */}
                                        <Box display="flex" alignItems="center">
                                            <TextField
                                                label="idadd"
                                                value={editableIdadd}
                                                onChange={(e) => setEditableIdadd(e.target.value)}
                                                fullWidth
                                                margin="normal"
                                                InputProps={{
                                                    readOnly: !isIdaddEditable,  // Greyed out unless toggled editable
                                                }}
                                                sx={{
                                                    input: {
                                                        color: isIdaddEditable ? 'black' : 'grey',  // Change text color when editable
                                                    },
                                                }}
                                            />
                                            <IconButton onClick={handleIdaddClick}>
                                                <EditIcon />
                                            </IconButton>
                                        </Box>

                                        {/* Year Field - Greyed out by default, editable when clicking the pencil icon */}
                                        <Box display="flex" alignItems="center">
                                            <TextField
                                                label="Year"
                                                value={editableYear}
                                                onChange={(e) => setEditableYear(e.target.value)}
                                                fullWidth
                                                margin="normal"
                                                InputProps={{
                                                    readOnly: !isYearEditable,  // Greyed out unless toggled editable
                                                }}
                                                sx={{
                                                    input: {
                                                        color: isYearEditable ? 'black' : 'grey',  // Change text color when editable
                                                    },
                                                }}
                                            />
                                            <IconButton onClick={handleYearClick}>
                                                <EditIcon />
                                            </IconButton>
                                        </Box>
                                    </>
                                )}

                                {/* Comment text input */}
                                <TextField
                                    label="Comment"
                                    value={commentText}
                                    onChange={(e) => setCommentText(e.target.value)}
                                    fullWidth
                                    margin="normal"
                                    multiline
                                    rows={4}
                                    required
                                />

                                {/* Submit button */}
                                <Button variant="contained" color="primary" type="submit" fullWidth>
                                    Submit Comment
                                </Button>
                            </form>
                        </Paper>
                    </AccordionDetails>
                </Accordion>
            </Box>
            <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
                <DialogTitle>Edit Comment</DialogTitle>
                <DialogContent>
                    {user && !globalRoles.isAdmin && (
                        <>
                            <input type="hidden" value={editFirstName} />
                            <input type="hidden" value={editLastName} />
                            <input type="hidden" value={editEmail} />
                        </>
                    )}

                    {/* If the user is an admin, show the fields but auto-populate them */}
                    {globalRoles.isAdmin && (
                        <>
                            <TextField
                                label="First Name"
                                value={editFirstName}
                                onChange={(e) => setEditFirstName(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label="Last Name"
                                value={editLastName}
                                onChange={(e) => setEditLastName(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label="Email"
                                value={editEmail}
                                onChange={(e) => setEditEmail(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                            {/* UID Field - Greyed out by default, editable when clicking the pencil icon */}
                            <Box display="flex" alignItems="center">
                                <TextField
                                    label="UID"
                                    value={editUID}
                                    onChange={(e) => setEditUID(e.target.value)}
                                    fullWidth
                                    margin="normal"
                                    InputProps={{
                                        readOnly: !isUIDEditable,  // Greyed out unless toggled editable
                                    }}
                                    sx={{
                                        input: {
                                            color: isUIDEditable ? 'black' : 'grey',  // Change text color when editable
                                        },
                                    }}
                                />
                                <IconButton onClick={handleUIDClick}>
                                    <EditIcon />
                                </IconButton>
                            </Box>

                            {/* IDADD Field - Greyed out by default, editable when clicking the pencil icon */}
                            <Box display="flex" alignItems="center">
                                <TextField
                                    label="idadd"
                                    value={editIdadd}
                                        onChange={(e) => setEditIdadd(e.target.value)}
                                    fullWidth
                                    margin="normal"
                                    InputProps={{
                                        readOnly: !isIdaddEditable,  // Greyed out unless toggled editable
                                    }}
                                    sx={{
                                        input: {
                                            color: isIdaddEditable ? 'black' : 'grey',  // Change text color when editable
                                        },
                                    }}
                                />
                                <IconButton onClick={handleIdaddClick}>
                                    <EditIcon />
                                </IconButton>
                            </Box>

                            {/* Year Field - Greyed out by default, editable when clicking the pencil icon */}
                            <Box display="flex" alignItems="center">
                                <TextField
                                    label="Year"
                                    value={editYear}
                                    onChange={(e) => setEditYear(e.target.value)}
                                    fullWidth
                                    margin="normal"
                                    InputProps={{
                                        readOnly: !isYearEditable,  // Greyed out unless toggled editable
                                    }}
                                    sx={{
                                        input: {
                                            color: isYearEditable ? 'black' : 'grey',  // Change text color when editable
                                        },
                                    }}
                                />
                                <IconButton onClick={handleYearClick}>
                                    <EditIcon />
                                </IconButton>
                            </Box>
                        </>
                    )}

                    {/* Comment text input */}
                    <TextField
                        label="Comment"
                        value={editCommentText}
                        onChange={(e) => setEditCommentText(e.target.value)}
                        fullWidth
                        margin="normal"
                        multiline
                        rows={4}
                        required
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenEditDialog(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleEditSubmit} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CommentsSection;
