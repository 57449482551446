import React, {useCallback, useEffect, useState} from 'react';
import {
    Grid,
    Box,
    Typography,
    IconButton,
    Button,
    TextField,
    DialogActions,
    Dialog,
    DialogTitle,
    DialogContent
} from '@mui/material';
import {Close, Delete, Edit, Flag, NavigateBefore, NavigateNext} from '@mui/icons-material';
import Comments from './Comments';
import { useAuth } from './authContext';
import { useProject } from './ProjectContext';
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import AddLocationIcon from '@mui/icons-material/AddLocation';

const SidePanelComponent = ({
    isSidePanelOpen,
    setIsSidePanelOpen,
    year,
    setYear,
    setSelectedMarker,
    setSelectedAddressLatLng,
    setShowContribution,
    setSelectedAddress,
    markerId,
    }) => {
    const { user, globalRoles, projectRoles } = useAuth();
    const [data, setData] = useState([]);
    const [selectedItems, setSelectedItems] = useState([{ year: year.toString(), data: { occ: "No Data Available for Selected Year." }}]);
    const [currentPage, setCurrentPage] = useState(0);
    const [isEditing, setIsEditing] = useState(false);
    const selectedItem = selectedItems[currentPage];
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const { project } = useProject();

    const [editFormData, setEditFormData] = useState({
        address: '',
        placeName: '',
        occupant: '',
        otherOccupantDetails: '',
        storyOfPlace: '',
        artifacts: '',
        notes: '',
        sources: '',
        projectid: ''
    });
    const [focusedField, setFocusedField] = useState(null); // Keep track of which field is focused

    const handleFocus = (fieldName) => {
        setFocusedField(fieldName); // Set the focused field
    };

    const handleBlur = () => {
        setFocusedField(null); // Reset when focus is lost
    };
    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setEditFormData({ ...editFormData, [name]: value });
    };
    const [currentItem, setCurrentItem] = useState(null);
    const handleOpenEditModal = (item) => {
        setCurrentItem(item);
        setEditFormData({
            address: item.data.address || '',
            placeName: item.data.placeName || '',
            occupant: item.data.occupant || '',
            otherOccupantDetails: item.data.otherOccupantDetails || '',
            storyOfPlace: item.data.storyOfPlace || '',
            artifacts: item.data.artifacts || '',
            notes: item.data.notes || '',
            sources: item.data.sources || '',
            projectid: item.data.projectid || ''
        });
        setIsEditModalOpen(true);
    };
    const handleSaveChanges = () => {
        handleEdit(currentItem, editFormData); // Use handleEdit with the current item and form data
        setIsEditModalOpen(false);
    };

    // Handle cancel
    const handleCancelEdit = () => {
        setIsEditModalOpen(false);
    };

    //const [formData, setFormData] = useState({ ...selectedItem.data }); // Pre-fill with selected data

    const updateHasDataForYears = async (address, year, hasData) => {
        try {
            const response = await fetch(`https://mapsite.whoseland.work/api/selectFromAdresses.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ address, year, hasData })
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            console.error('An error occurred while updating hasDataForYears:', error);
        }
    };
    const fetchDataForMarker = async (markerId) => {
        try {
            const response = await fetch(`https://mapsite.whoseland.work/api/fetchDataForMarkerU.php?markerId=${markerId}&projectid=${project.projectid}`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            let rawData = await response.json();

            let allData = rawData.map(entry => {
                if (entry.source_table.startsWith('hpn_')) {
                    const yearSuffix = entry.year.toString().slice(-2);
                    return {
                        year: parseInt(entry.year, 10),
                        data: {
                            idadd: entry['idadd'],
                            add: entry['add'],
                            occ: entry[`occ${yearSuffix}`],
                            own: entry[`own${yearSuffix}`],
                            artifact: entry[`artifact${yearSuffix}`],
                            element: entry[`element${yearSuffix}`],
                            source: entry[`source${yearSuffix}`],
                            note: entry[`note${yearSuffix}`],
                        },
                        source: entry.source_table
                    };
                } else {
                    return {
                        year: parseInt(entry.year, 10),
                        data: {
                            ...entry,
                        },
                        source: entry.source_table
                    };
                }
            });

            setData(allData);
        } catch (error) {
            console.error('An error occurred while fetching data:', error);
        }
    };

    const filterDataForYear = useCallback(() => {
        console.log(`Current year state: ${year}, Data state length: ${data.length}`);
        let filteredData = data.filter(item => item.year === year);

        // Prioritize contributions_new data by sorting filteredData
        filteredData.sort((a, b) => {
            if (a.source === "contributions_new" && b.source !== "contributions_new") {
                return -1;
            } else if (a.source !== "contributions_new" && b.source === "contributions_new") {
                return 1;
            }
            return 0;
        });

        console.log(`Filtered and sorted data for year ${year}:`, filteredData);
        if (filteredData.length > 0) {
            setSelectedItems(filteredData);
        } else {
            // Provide a fallback if there's no data
            setSelectedItems([{ year: year.toString(), data: { occ: "No Data Available for Selected Year." }}]);
        }
        setCurrentPage(0);
        console.log(`Filtering for year: ${year}`, filteredData);

    }, [data, year]);

    useEffect(() => {
        if (isSidePanelOpen && markerId) {
            fetchDataForMarker(markerId);
        }
    }, [isSidePanelOpen, markerId]);

    // Filter data when `data` or `year` changes
    useEffect(() => {
        if (data.length > 0) {
            filterDataForYear();
        }
    }, [data, year, filterDataForYear]);


    useEffect(() => {
        // Find the index of the item for the current decade.
        const index = selectedItems.findIndex(item => item.year === year);

        // If an item for the current decade was found, update currentPage.
        if (index !== -1) {
            setCurrentPage(index);
        }
    }, [year, selectedItems]);
    const handleNext = () => {
        if (currentPage < selectedItems.length - 1) { // Check if we can go forward
            const nextPage = currentPage + 1;
            setCurrentPage(nextPage);
            setYear(selectedItems[nextPage].year); // Update year based on the new page's data
        }
    };

    const handlePrevious = () => {
        if (currentPage > 0) { // Check if we can go back
            const prevPage = currentPage - 1;
            setCurrentPage(prevPage);
            setYear(selectedItems[prevPage].year); // Update year based on the new page's data
        }
    };
    const handleEdit = async () => {
        try {
            const response = await fetch('https://mapsite.whoseland.work/api/EDR_CONT.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    action: 'edit', // Specify action type as 'edit'
                    id: currentItem.data.idcontributions_new, // Use the id from currentItem
                    contributerid: user.uid,
                    address: currentItem.data.address,
                    placeName: editFormData.placeName,
                    occupant: editFormData.occupant,
                    otherOccupantDetails: editFormData.otherOccupantDetails,
                    storyOfPlace: editFormData.storyOfPlace,
                    artifacts: editFormData.artifacts,
                    notes: editFormData.notes,
                    sources: editFormData.sources,
                    projectid: editFormData.projectid,
                }),
            });

            const result = await response.json();
            if (result.success) {
                alert('Submission edited successfully!');
                setIsEditModalOpen(false); // Close edit modal
                // Refresh data or update the state as needed to reflect changes
            } else {
                alert('Failed to edit submission.');
            }
        } catch (error) {
            console.error('Error editing submission:', error);
            alert('An error occurred while editing the submission.');
        }
    };


    const handleDelete = async () => {
        const response = await fetch('https://mapsite.whoseland.work/api/EDR_CONT.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                action: 'delete', // Specify action type as 'delete'
                id: selectedItem.data.idcontributions_new,
                contributerid: user.uid,
            }),
        });

        const result = await response.json();
        if (result.success) {
            alert('Submission deleted successfully!');
            setData([]); // Clear the data state
            setSelectedItems([]); // Clear the selectedItems state
            setIsSidePanelOpen(false); // Close the panel
        } else {
            alert('Failed to delete submission.');
        }
    };


    // Handle Report Submission
    const handleReport = async () => {
        const response = await fetch('https://mapsite.whoseland.work/api/EDR_CONT.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                action: 'report', // Specify action type as 'report'
                id: selectedItem.idcontributions_new,
                reported_by: user ? user.uid : 'anonymous', // If not logged in, mark as anonymous
                report_reason: 'Inappropriate content', // Example report reason, could be user-defined
            }),
        });

        const result = await response.json();
        if (result.success) {
            alert('Submission reported successfully!');
        } else {
            alert('Failed to report submission.');
        }
    };


    const canEditOrDelete = (item) => {
        const projectAdminRole = project.projectid + '_Admin'; // Construct the specific project admin role

        return (
            (user && item.data.contributerid === user.uid) ||
            globalRoles.isAdmin ||
            globalRoles.isDev ||
            (projectRoles && projectRoles.length > 0 && projectRoles.includes(projectAdminRole)) // Check if the user has a project-specific admin role
        );
    };

    if (!isSidePanelOpen) return null;

    return (
        <Grid
            item
            xs={12}
            sm={12}
            md={12}
            sx={{ backgroundColor: '#f5f5f5', overflowY: 'auto', position: 'relative' }}
        >
            <Dialog open={isEditModalOpen} onClose={handleCancelEdit}>
                <DialogTitle>Edit Item</DialogTitle>
                <DialogContent>
                    <TextField
                        margin="dense"
                        label="Place Name"
                        name="placeName"
                        value={editFormData.placeName}
                        onChange={handleFormChange}
                        onFocus={() => handleFocus('placeName')} // Handle focus
                        onBlur={handleBlur} // Handle blur
                        fullWidth
                        multiline={focusedField === 'placeName'} // Multiline only when focused
                        rows={focusedField === 'placeName' ? 4 : 1} // Expand to 4 rows when focused, return to 1 when unclicked
                    />
                    <TextField
                        margin="dense"
                        label="Occupant"
                        name="occupant"
                        value={editFormData.occupant}
                        onChange={handleFormChange}
                        onFocus={() => handleFocus('occupant')}
                        onBlur={handleBlur}
                        fullWidth
                        multiline={focusedField === 'occupant'}
                        rows={focusedField === 'occupant' ? 4 : 1}
                    />
                    <TextField
                        margin="dense"
                        label="Occupant Details"
                        name="otherOccupantDetails"
                        value={editFormData.otherOccupantDetails}
                        onChange={handleFormChange}
                        onFocus={() => handleFocus('otherOccupantDetails')}
                        onBlur={handleBlur}
                        fullWidth
                        multiline={focusedField === 'otherOccupantDetails'}
                        rows={focusedField === 'otherOccupantDetails' ? 4 : 1}
                    />
                    <TextField
                        margin="dense"
                        label="Story of Place"
                        name="storyOfPlace"
                        value={editFormData.storyOfPlace}
                        onChange={handleFormChange}
                        onFocus={() => handleFocus('storyOfPlace')}
                        onBlur={handleBlur}
                        fullWidth
                        multiline={focusedField === 'storyOfPlace'}
                        rows={focusedField === 'storyOfPlace' ? 4 : 1}
                    />
                    <TextField
                        margin="dense"
                        label="Associated Artifacts"
                        name="artifacts"
                        value={editFormData.artifacts}
                        onChange={handleFormChange}
                        onFocus={() => handleFocus('associatedArtifacts')}
                        onBlur={handleBlur}
                        fullWidth
                        multiline={focusedField === 'associatedArtifacts'}
                        rows={focusedField === 'associatedArtifacts' ? 4 : 1}
                    />
                    <TextField
                        margin="dense"
                        label="Additional Notes"
                        name="notes"
                        value={editFormData.notes}
                        onChange={handleFormChange}
                        onFocus={() => handleFocus('notes')}
                        onBlur={handleBlur}
                        fullWidth
                        multiline={focusedField === 'notes'}
                        rows={focusedField === 'notes' ? 4 : 1}
                    />
                    <TextField
                        margin="dense"
                        label="Sources"
                        name="sources"
                        value={editFormData.sources}
                        onChange={handleFormChange}
                        onFocus={() => handleFocus('source')}
                        onBlur={handleBlur}
                        fullWidth
                        multiline={focusedField === 'source'}
                        rows={focusedField === 'source' ? 4 : 1}
                    />
                    <TextField
                        margin="dense"
                        label="Project ID"
                        name="projectid"
                        value={editFormData.projectid}
                        onChange={handleFormChange}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            if (window.confirm("Are you sure you want to delete this entry?")) {
                                handleDelete(selectedItems[currentPage].data.idcontributions_new);
                            }
                        }}
                        color="secondary"
                        sx={{ marginRight: 'auto' }}
                    >
                        DELETE ENTRY
                    </Button>                    <Button onClick={handleCancelEdit} color="primary">Cancel</Button>
                    <Button onClick={handleSaveChanges} color="primary">Save</Button>
                </DialogActions>
            </Dialog>
            <Box sx={{ position: 'relative', height: '100%', overflow: 'hidden' }}>
                <Box sx={{ position: 'absolute', top: 0, right: 0, zIndex: 10000 }}>
                    <IconButton
                        aria-label="close"
                        onClick={() => {
                            setIsSidePanelOpen(false);
                            setShowContribution(false);
                            setSelectedAddressLatLng(null);
                            setSelectedMarker(null);
                            setSelectedAddress('');
                        }}
                    >
                        <Close />
                    </IconButton>
                </Box>
                <Box sx={{ position: 'absolute', top: 0, left: 0, zIndex: 10000 }}>
                    <IconButton onClick={() => handleReport(selectedItems[currentPage].id)}>
                        <Flag />
                    </IconButton>
                </Box>
                {selectedItems.length > 0 && (
                    <Box sx={{ position: 'relative', height: '100%', width: '100%', paddingTop: '10px', paddingBottom: '10px', display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', alignContent: 'center', alignItems: 'center' }}>
                        <Box data-layer="f9cd7771-1cca-4304-b8a1-5e0053c24bbb" className="web19201">
                            <Box data-layer="97a51b9e-e8b6-4065-902a-510856fb5962" className="rectangle2">
                                {year !== 1900 && (
                                    <IconButton aria-label="Previous" onClick={() => setYear(year - 10)}><NavigateBefore /></IconButton>
                                )}
                                <Box data-layer="9634d2f2-5cc2-42fe-a577-937e4f488fa8" className="xYear">
                                    {(selectedItems[currentPage].year === 2030 || year === 2030) ? (
                                        <>
                                            Vision <br/> (Beyond 2020)
                                        </>
                                    ): (
                                        selectedItems[currentPage].year
                                    )}
                                </Box>
                                {year !== 2030 && (
                                    <IconButton aria-label="Next" onClick={() => setYear(year + 10)}><NavigateNext /></IconButton>
                                )}
                                {year === 2030 && (
                                    <IconButton sx={{display: 'none'}}><NavigateNext /></IconButton>
                                )}
                            </Box>
                        </Box>
                        <Box className="page-list">
                            <Typography className='page-header'>
                                {selectedItems[currentPage].source === "contributions_new" ? selectedItems[currentPage].data.placeName : selectedItems[currentPage].data.occ || "No Data Available for Selected Year."}
                            </Typography>
                            {selectedItems[currentPage].source === "contributions_new" ? (
                                <Box className='page-list-container'>
                                    <Typography className='page-item'><span className='page-item-key'>Year: </span>{(selectedItems[currentPage].year === 2030 || year === 2030) ? (
                                        <>
                                            Vision <br/> (Beyond 2020)
                                        </>
                                    ): (
                                        selectedItems[currentPage].year
                                    )}</Typography>
                                    <Typography className='page-item'><span className='page-item-key'>Address: </span>{selectedItems[currentPage].data.address}</Typography>
                                    <Typography className='page-item'><span className='page-item-key'>Place Name: </span>{selectedItems[currentPage].data.placeName}</Typography>
                                    <Typography className='page-item'><span className='page-item-key'>Occupant: </span>{selectedItems[currentPage].data.occupant}</Typography>
                                    <Typography className='page-item'><span className='page-item-key'>Occupant Details: </span>{selectedItems[currentPage].data.otherOccupantDetails}</Typography>
                                    <Typography className='page-item'><span className='page-item-key'>Story of Place: </span>{selectedItems[currentPage].data.storyOfPlace}</Typography>
                                    <Typography className='page-item'><span className='page-item-key'>Associated Artifacts: </span>{selectedItems[currentPage].data.artifacts}</Typography>
                                    <Typography className='page-item'><span className='page-item-key'>Additional Notes: </span>{selectedItems[currentPage].data.notes}</Typography>
                                    <Typography className='page-item'><span className='page-item-key'>Sources: </span>{selectedItems[currentPage].data.sources}</Typography>
                                    <Box className='page-item'>
                                        <Typography className='page-item-key'>Uploaded Files: </Typography>
                                        <Box>
                                            {selectedItems[currentPage].data.uploaded_files ? (
                                                selectedItems[currentPage].data.uploaded_files.split(', ').map((fileUrl, index) => (
                                                    <Box key={index} sx={{ marginBottom: '8px' }}>
                                                        <a href={fileUrl} target="_blank" rel="noopener noreferrer">{fileUrl}</a>
                                                    </Box>
                                                ))
                                            ) : (
                                                <Typography>No files uploaded.</Typography>
                                            )}
                                        </Box>
                                    </Box>
                                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                        {canEditOrDelete(selectedItems[currentPage]) && (
                                            <Button
                                                color='secondary'
                                                sx={{ marginRight: 'auto' }}
                                                onClick={() => handleOpenEditModal(selectedItems[currentPage])}
                                                startIcon={<EditIcon />}
                                            >
                                                EDIT
                                            </Button>
                                        )}
                                        <Button
                                            variant="contained"
                                            onClick={() => { setShowContribution(true); setIsSidePanelOpen(false) }}
                                            startIcon={<AddCircleIcon />}
                                        >
                                            Add A Story
                                        </Button>
                                    </Box>
                                    <Box>
                                        <Comments idadd={selectedItems[currentPage].data.idadd} year={year} />
                                    </Box>
                                </Box>
                            ) : (
                                <Box className='page-list-container'>
                                    <Typography className='page-item'><span className='page-item-key'>Address: </span>{selectedItems[currentPage].data.add}</Typography>
                                    <Typography className='page-item'><span className='page-item-key'>Occupant: </span>{selectedItems[currentPage].data.occ}</Typography>
                                    <Typography className='page-item'><span className='page-item-key'>Owner: </span>{selectedItems[currentPage].data.own}</Typography>
                                    <Typography className='page-item'><span className='page-item-key'>Historical Element: </span>{selectedItems[currentPage].data.element}</Typography>
                                    <Typography className='page-item'><span className='page-item-key'>Historical Artifact: </span>{selectedItems[currentPage].data.artifact}</Typography>
                                    <Typography className='page-item'><span className='page-item-key'>Sources: </span>{selectedItems[currentPage].data.source}</Typography>
                                    <Typography className='page-item'><span className='page-item-key'>Notes: </span>{selectedItems[currentPage].data.note}</Typography>
                                    <Typography className='page-item'><span className='page-item-key'>MGRS: </span>{selectedItems[currentPage].data.idadd}</Typography>
                                    <br/>
                                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button
                                            variant="contained"
                                            onClick={() => { setShowContribution(true); setIsSidePanelOpen(false) }}
                                            startIcon={<AddCircleIcon />}
                                        >
                                            Add Story
                                        </Button>
                                    </Box>

                                    {selectedItems[currentPage].data.idadd && (
                                        <Box>
                                            <Comments idadd={selectedItems[currentPage].data.idadd} year={year} />
                                        </Box>
                                    )}
                                </Box>
                            )}
                        </Box>
                        <Box className="navigation-controls">
                            <Button variant="outlined" onClick={handlePrevious}>Previous</Button>
                            <Typography className="page-count-indicator">{`${currentPage + 1} of ${selectedItems.length}`}</Typography>
                            <Button variant="outlined" onClick={handleNext}>Next</Button>
                        </Box>
                    </Box>
                )}
            </Box>
        </Grid>
    );
};

export default SidePanelComponent;
