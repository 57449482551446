// ProjectContext.js
import React, { createContext, useState, useContext } from 'react';

const ProjectContext = createContext();

export const useProject = () => useContext(ProjectContext);

export const ProjectProvider = ({ children }) => {
    const [project, setProject] = useState({
        projectsid: '',
        projectid: '',
        project_name: '',
        project_url_slug: '',
        description: '',
        center_lat: 39.833333,
        center_lng: -98.583333,
        zoom_level: 5,
        logo_url: '',
    });



    return (
        <ProjectContext.Provider value={{ project, setProject }}>
            {children}
        </ProjectContext.Provider>
    );
};
