import React, { useContext, useEffect, useState, useRef, useCallback } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap, CircleMarker, ImageOverlay, ImageOverlayProps, LayersControl, useMapEvents } from 'react-leaflet';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import {Grid, Typography, Button, IconButton, Paper, TextField, Autocomplete, Modal, MenuItem} from "@mui/material";
import 'leaflet/dist/leaflet.css';
import './decade_map.css';
import './web19201.css';
import ContributionComp from "./ContributionComp";
import baseLayerOptions from './baseLayerOptions';
import { useUserPreferences } from './UserPreferencesContext';
import Preferences from './Preferences';
import WelcomePopup from "./WelcomePopup";
import ErrorBoundary from './ErrorBoundary';
import AuthForm from './authForm';
import { useAuth } from './authContext';
import SidePanelComponent from "./SidePanelComponent";
import { useProject } from './ProjectContext';
import { useNavigate } from 'react-router-dom';
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import InfoIcon from '@mui/icons-material/Info';
import LayersIcon from '@mui/icons-material/Layers';




function valuetext(value) {
    return `${value}`;
}
function MapComponent() {
    const { preferences } = useUserPreferences();
    const [layerUrl, setLayerUrl] = useState(baseLayerOptions[preferences.baseLayer].url);
    const [layerAttribution, setLayerAttribution] = useState(baseLayerOptions[preferences.baseLayer].attribution);
    const [layerMaxZoom, setLayerMaxZoom] = useState(baseLayerOptions[preferences.baseLayer].maxZoom);
    const [layerMaxNativeZoom, setLayerMaxNativeZoom] = useState(baseLayerOptions[preferences.baseLayer].maxNativeZoom);
    const [layerSubdomains, setLayerSubdomains] = useState(baseLayerOptions[preferences.baseLayer].subdomains || []);
    const mapRef = useRef();
    const { globalRoles, projectRoles } = useAuth();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { project } = useProject();
    const [mapCenter, setMapCenter] = useState([project.center_lat, project.center_lng]);
    const [zoom, setZoom] = useState(project.zoom_level);
    const navigate = useNavigate();


    useEffect(() => {
        if (project.center_lat && project.center_lng && project.zoom_level) {
            setMapCenter([project.center_lat, project.center_lng]);
            setZoom(project.zoom_level);
        }
    }, [project]);


    useEffect(() => {
        const baseLayer = baseLayerOptions[preferences.baseLayer];
        setLayerUrl(baseLayer.url);
        setLayerAttribution(baseLayer.attribution);
        setLayerMaxZoom(baseLayer.maxZoom);
        setLayerMaxNativeZoom(baseLayer.maxNativeZoom);
        setLayerSubdomains(baseLayer.subdomains || []);
    }, [preferences.baseLayer]);

    const [year, setYear] = useState(1950);
    const [mapdata, setMapdata] = useState([]);
    const [showContribution, setShowContribution] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState('');
    const [selectedAddressLatLng, setSelectedAddressLatLng] = useState(null)
    const [selectMode, setSelectMode] = useState(false);
    const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [markerId, setMarkerId] = useState([]);
    const [currentZoom, setCurrentZoom] = useState(zoom);
    const [addressYearMap, setAddressYearMap] = useState({});
    const [showPreferences, setShowPreferences] = useState(false);
    const [showLayersAdmin, setShowLayersAdmin] = useState(false);
    const [showUpdateAdmin, setShowUpdateAdmin] = useState(false);
    const [showCommentAdmin, setShowCommentAdmin] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [searchData, setSearchData] = useState([]);
    const [triggeredByHowItWorks, setTriggeredByHowItWorks] = useState(false);
    const [overlayOpacity, setOverlayOpacity] = useState(1);
    const [overlays, setOverlays] = useState([]);
    const [openPopup, setOpenPopup] = useState(false);
    const [showOverlayOpacity, setShowOverlayOpacity] = useState(true);

    const adminOrMod = () => {
        const projectAdminRole = project.projectid + '_Admin'; // Construct the specific project admin role

        return (
            globalRoles?.isAdmin || // Optional chaining in case globalRoles is undefined
            globalRoles?.isDev || // Optional chaining for Dev role
            (projectRoles && projectRoles.length > 0 && projectRoles.includes(projectAdminRole)) // Check for project-specific admin role
        );
    };

    useEffect(() => {
        const shouldShowPopup = localStorage.getItem('showWelcomePopup');
        if (shouldShowPopup !== 'false') {
            setOpenPopup(true);
        }
    }, []);

    const marks = [
        {
            value: 1900,
            label: '1900s',
        },
        {
            value: 1910,
            label: '1910s',
        },
        {
            value: 1920,
            label: '1920s',
        },
        {
            value: 1930,
            label: '1930s',
        },
        {
            value: 1940,
            label: '1940s',
        },
        {
            value: 1950,
            label: '1950s',
        },
        {
            value: 1960,
            label: '1960s',
        },
        {
            value: 1970,
            label: '1970s',
        },
        {
            value: 1980,
            label: '1980s',
        },
        {
            value: 1990,
            label: '1990s',
        },
        {
            value: 2000,
            label: '2000s',
        },
        {
            value: 2010,
            label: '2010s',
        },
        {
            value: 2020,
            label: '2020s',
        },
        {
            value: 2030,
            label:
                <Paper elevation={2} radius={3}
                       sx={{paddingTop: '3px', paddingBottom: '3px', paddingLeft: '5px', paddingRight: '5px', filter: openPopup === true ? 'grayscale(100) opacity(1) brightness(0.5)' : 'none'}}>
                    <Typography variant="body2">
                        Vision
                    </Typography>
                </Paper>
        },

    ];


    const handleMenuClick = (event) => {
        // Toggle the menu: close it if already open, open it if closed
        if (anchorEl) {
            setAnchorEl(null); // Menu is open, close it
        } else {
            setAnchorEl(event.currentTarget); // Menu is closed, open it
        }
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };


    const handleOverlayOpacityChange = (event, newValue) => {
        setOverlayOpacity(newValue);
    };
    const handleSearch = (query) => {
        if (!query || !searchData || searchData.length === 0) {
            setSearchResults([]);
            return;
        }

        console.log("Searching for query:", query);
        const results = [];

        searchData.forEach((item) => {
            if (!item) return;
            const addressMatch = item.address && item.address.toLowerCase().includes(query.toLowerCase());
            const keywordMatch = Object.entries(item).find(([key, value]) =>
                value && typeof value === 'string' && value.toLowerCase().includes(query.toLowerCase())
            );

            if (addressMatch || keywordMatch) {
                const keywordResult = keywordMatch ? `${keywordMatch[0]}: ${keywordMatch[1]}` : '';
                const year = item.year ? parseInt(item.year, 10) : (item.source_table ? parseInt(item.source_table.split('_')[1], 10) : null);
                results.push({
                    address: item.address,
                    idadd: item.idadd,
                    lat: item.lat,
                    lng: item.lng,
                    year: year,
                    keyword: keywordResult
                });
            }
        });

        console.log("Search results:", results);
        setSearchResults(results);
    };

    const handleSearchSelect = (event, value) => {
        if (value) {
            //fetchDataForMarker(value.idadd, value.address)
            console.log("Selected search result:", value);
            setSelectedMarker(value.idadd);
            setSelectedAddress(value.address);
            setYear(parseInt(value.year, 10));
            mapRef.current.setView([value.lat, value.lng], zoom);
            setIsSidePanelOpen(true);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch(event.target.value);
        }
    };

    const fetchAllData = async () => {
        try {
            const response = await fetch(`https://mapsite.whoseland.work/api/fetchAllData.php`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            let fetchedData = await response.json();

            setSearchData(fetchedData);
            console.log("Fetched data for search:", fetchedData);
        } catch (error) {
            console.error('An error occurred while fetching search data:', error);
        }
    };


    const MapEventsHandler = () => {

        const map = useMap();  // get a reference to the map instance

        useEffect(() => {
            const leafletContainer = map.getContainer();
            if (selectMode) {
                leafletContainer.classList.add('selecting');
            } else {
                leafletContainer.classList.remove('selecting');
            }
        }, [map]);
        useMapEvents({
            zoomend: () => {
                setCurrentZoom(map.getZoom());
                console.log(currentZoom);
            },
            click: async (e) => {
                if (selectMode) {
                    const apiKey = "AIzaSyCllzLZfdBPfaSa65Y4vwTIrONb3IuZ4ac";
                    const { lat, lng } = e.latlng;
                    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`);
                    if (response.ok) {
                        const geocodeData = await response.json();
                        if (geocodeData.results && geocodeData.results.length > 0) {
                            const address = geocodeData.results[0].formatted_address;
                            setSelectedAddress(address);
                            console.log(address)
                            setSelectedAddressLatLng(e.latlng)
                            setSelectMode(false); // reset selectMode after successful selection
                        } else {
                            console.error('No results found for these coordinates');
                        }
                    } else {
                        console.error(`Geocoding error: ${response.status}`);
                    }
                }
            }
        });
        return null;

    };



    useEffect(() => {
        const geocodeAddress = async () => {
            const apiKey = "AIzaSyCllzLZfdBPfaSa65Y4vwTIrONb3IuZ4ac";
            const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(selectedAddress)}&key=${apiKey}`);
            if (response.ok) {
                const geocodeData = await response.json();
                if (geocodeData.results && geocodeData.results.length > 0) {
                    const { geometry } = geocodeData.results[0];
                    setSelectedAddressLatLng({lat: geometry.location.lat, lng: geometry.location.lng});
                } else {
                    console.error('No results found for this address');
                }
            } else {
                console.error(`Geocoding error: ${response.status}`);
            }
        }

        if (selectedAddress) {
            geocodeAddress();
        }
    }, [selectedAddress]);

    const fetchData = async (projectid) => {
        try {
            const url = projectid
                ? `https://mapsite.whoseland.work/api/selectFromAdresses.php?projectid=${projectid}`
                : `https://mapsite.whoseland.work/api/selectFromAdresses.php`; // Fetch all if no projectid is provided

            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            let fetchedData = await response.json();

            const structuredData = fetchedData.map(item => ({
                lat: item.lat,
                lng: item.lng,
                address: item.address,
                idadd: item.idadd,
                hasDataForYears: item.hasDataForYears ? item.hasDataForYears.split(',').map(Number) : []
            }));

            setMapdata(structuredData); // Assuming setMapdata is defined
        } catch (error) {
            console.error('An error occurred while fetching data:', error);
        }
    };



// Ensuring that the data is fetched and displayed on map load
    useEffect(() => {
        fetchData(project.projectid);
    }, [project]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (mapRef.current) {
                mapRef.current.invalidateSize(); // This will be called after the delay
            }
        }, 1000);  // Adjust the delay as needed (in milliseconds, 500ms = 0.5 seconds)

        // Cleanup function to clear the timeout if the component unmounts or if the dependency changes
        return () => clearTimeout(timeoutId);
    }, [mapdata]);



    function calculateRadius(currentZoom) {
        // Ensure currentZoom is a valid number
        if (!currentZoom || isNaN(currentZoom)) {
            return 3.5;  // Default radius if zoom is invalid
        }

        // Return a minimum radius for low zoom levels
        if (currentZoom < 16) {
            return 3.5;
        }

        // Calculate radius for higher zoom levels and ensure it's positive
        const radius = (currentZoom / 2) - 4;
        return radius > 0 ? radius : 3.5;  // Ensure a minimum radius of 3.5
    }

    const refreshData = async () => {
        await fetchData();
    };
    const preprocessData = (data) => {
        const map = {};
        data.forEach(item => {
            const address = item.data.add;
            const year = item.year;
            if (!map[address]) {
                map[address] = new Set();
            }
            map[address].add(year);
        });
        setAddressYearMap(map);
    };

    //useEffect(() => {
    //    preprocessData(data);
    //}, [data]);

    useEffect(() => {
    }, [year, addressYearMap]);

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.invalidateSize();
        }
    }, [isSidePanelOpen, showLayersAdmin, showCommentAdmin, showUpdateAdmin, showContribution, showPreferences]);


    const handleShowPopup = () => {
        setOpenPopup(true);
        setTriggeredByHowItWorks(true);
    };

    const handleClosePopup = () => {
        setOpenPopup(false);
    };

    const { user, loading, logout } = useAuth();
    const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility

    const handleLogin = async () => {
        try {
            setIsModalOpen(true);
        } catch (error) {
            console.error('Error opening login form:', error);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false); // Close the modal
    };

    const [overlayLayers, setOverlayLayers] = useState([]);
    const [selectedLayers, setSelectedLayers] = React.useState([]);

    useEffect(() => {
        if (project) {
            fetch(`https://mapsite.whoseland.work/api/fetchOverlayCity.php?projectid=${project.projectid}`)
                .then((response) => response.json())
                .then((data) => {
                    console.log('Fetched overlay layers: ', data); // Log to confirm the fetched data
                    if (Array.isArray(data)) {
                        setOverlayLayers(data); // Set state if data is valid
                    } else {
                        console.error('Expected an array but got:', data);
                    }
                })
                .catch((error) => console.error("Error fetching overlay layers: ", error));
        }
        else {
            fetch('https://mapsite.whoseland.work/api/fetchOverlay.php')
                .then((response) => response.json())
                .then((data) => {
                    console.log('Fetched overlay layers: ', data); // Log to confirm the fetched data
                    if (Array.isArray(data)) {
                        setOverlayLayers(data); // Set state if data is valid
                    } else {
                        console.error('Expected an array but got:', data);
                    }
                })
                .catch((error) => console.error("Error fetching overlay layers: ", error));
        }

    }, []);
    const getGridSize = (isSidePanelOpen, showPreferences, showLayersAdmin, showCommentAdmin) => {
        if (isSidePanelOpen) return { xs: 0, sm: 6, md: 8 };
        if (showPreferences) return { xs: 0, sm: 6, md: 8 };
        if (showContribution) return { xs: 0, sm: 6, md: 8 };
        if (showLayersAdmin) return { xs: 0, sm: 0, md: 0 };
        if (showCommentAdmin) return { xs: 0, sm: 0, md: 0 };
        if (showUpdateAdmin) return { xs: 0, sm: 0, md: 0 };
        return { xs: 12, sm: 12, md: 12 }; // Default case
    };


    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', width: '100vw' }}>
            <WelcomePopup open={openPopup} onClose={handleClosePopup} triggeredByHowItWorks={triggeredByHowItWorks} />
            <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="login-modal"
                aria-describedby="login-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '55%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        pt: 0,
                        pb: 4,
                        pr: 4,
                        pl: 4,
                        borderRadius: 2,
                    }}
                >
                    <AuthForm onSuccess={handleCloseModal} />
                </Box>
            </Modal>
            <Box sx={{ height: '90px', backgroundColor: 'black', width: '100%', position: 'relative' }}>
                <img src={project?.logo_url || './LOGO-5.png'} alt="Logo" style={{ height: '50px', position: 'absolute', top: 20, left: 80 }} />
                <Box sx={{ position: 'absolute', left: 300, top: 30 }}>
                    <Typography variant="h5" sx={{ color: "#f5f5f5" }}>{project?.project_name || 'Whoseland Archival Map'}</Typography>
                </Box>
                <Box sx={{ position: 'absolute', top: 0, right: 0, zIndex: 1000, display: 'flex', alignContent: 'center', alignItems:'center', justifyContent: 'center', height: '100%', width: 200 }}>
                    {loading ? (
                        <Typography>Loading...</Typography>
                    ) : user ? (
                        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                            <Typography variant="body1" sx={{ marginRight: 2, color: "#f5f5f5" }}>
                                Welcome, {user.displayName || user.email}
                            </Typography>
                            <Button startIcon={<LogoutIcon />} variant="contained" color="secondary" onClick={logout}>
                                Logout
                            </Button>
                        </Box>
                    ) : (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleLogin}
                            sx={{ height: 50 }}
                            startIcon={<LoginIcon />}
                        >
                            Login
                        </Button>
                    )}
                </Box>
            </Box>
            <Box sx={{ width: "100%", height: '70px', position: 'relative', overflow: 'hidden' }}>
                <Grid container spacing={2} alignItems="center" sx={{ backgroundColor: '#f5f5f5', borderRadius: '0px', paddingLeft: '16px', paddingRight: '16px', paddingTop: '6px', paddingBottom: '6px' }}>
                    <Grid item xs={2} sm={1} md={1} sx={{ display: 'flex', alignItems: 'center' }}>
                        {adminOrMod() && (
                            <div>
                                <Button
                                    aria-controls="admin-menu"
                                    aria-haspopup="true"
                                    onClick={() => navigate('/admin')}
                                    variant="contained"
                                    color="primary"
                                >
                                    Admin Console
                                </Button>
                            </div>

                        )}
                    </Grid>
                    <Grid item xs={7} sm={9} md={9} sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                        zIndex: 10000
                    }}>
                        <Typography variant="body1"><strong></strong></Typography>
                        <Slider
                            aria-label="Time-Slider"
                            defaultValue={1950}
                            getAriaValueText={valuetext}
                            min={1900}
                            max={2030}
                            step={10}
                            value={year}
                            marks={marks}
                            sx={{ width: '90%' }}
                            onChange={(e, newValue) => setYear(newValue)}
                        />
                    </Grid>
                    <Grid item xs={3} sm={2} md={2} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        {isSidePanelOpen &&
                            <Button
                                variant="contained"
                                onClick={() => { setShowContribution(true); setIsSidePanelOpen(false) }}
                                startIcon={<AddLocationAltIcon />}
                            >
                                Add New Place
                            </Button>
                        }
                        {!isSidePanelOpen &&
                            <Button
                                variant="contained"
                                onClick={() => { setShowContribution(true); setIsSidePanelOpen(false) }}
                                startIcon={<AddLocationAltIcon />}
                            >
                                Add A Place
                            </Button>
                        }
                    </Grid>
                </Grid>
            </Box>

            <Grid container sx={{ flexGrow: 1 }}>
                {/* Map Section */}
                <Grid
                    item
                    {...getGridSize(isSidePanelOpen, showPreferences, showLayersAdmin, showCommentAdmin)}
                    sx={{ position: 'relative', height: '100%' }}
                >
                    <Box sx={{ position: 'absolute', bottom: 24, right: 20, zIndex: 1000 }}>
                        <Button variant="contained"
                                onClick={handleShowPopup}
                                startIcon={<InfoIcon />}
                        >
                            About Map
                        </Button>
                    </Box>
                    <Box sx={{ position: 'absolute', bottom: 75, right: 20, zIndex: 1000 }}>
                        <Button
                            variant="contained"
                            onClick={() => setShowPreferences(!showPreferences)}
                            startIcon={<LayersIcon />}
                        >
                            Map Layers
                        </Button>
                    </Box>
                    {mapCenter && zoom ? (
                        <MapContainer
                            center={mapCenter}
                            zoom={zoom}
                            ref={mapRef}
                            style={{ height: '100%', width: '100%' }}
                        >
                            <TileLayer
                                url={baseLayerOptions[preferences.baseLayer].url}
                                attribution={baseLayerOptions[preferences.baseLayer].attribution}
                                maxZoom={baseLayerOptions[preferences.baseLayer].maxZoom}
                                maxNativeZoom={baseLayerOptions[preferences.baseLayer].maxNativeZoom}
                            />

                            {Array.isArray(selectedLayers) && selectedLayers.map((layer, index) => (
                                    <TileLayer
                                        key={layer.id}
                                        url={layer.url}
                                        maxZoom={layer.max_zoom || 20}
                                        maxNativeZoom={layer.max_native_zoom || 20}
                                        opacity={overlayOpacity}
                                    />
                            ))}


                            {mapdata.length > 0 && mapdata.map((item, index) => {
                                const address = item.address;
                                const hasData = item.hasDataForYears.includes(year);
                                return (
                                    <CircleMarker
                                        key={index}
                                        center={[item.lat, item.lng]}
                                        eventHandlers={{
                                            click: async () => {
                                                if (selectedMarker === index) {
                                                    setSelectedMarker(null);
                                                    setIsSidePanelOpen(false);
                                                } else {
                                                    setSelectedMarker(index);
                                                    setMarkerId(item.idadd, item.address)
                                                    setIsSidePanelOpen(true);
                                                    setSelectedAddress(item.address);
                                                    setShowPreferences(false);
                                                }
                                            }
                                        }}
                                        pathOptions={{
                                            color: selectedMarker === index ? "red" : hasData ? "blue" : "grey",
                                            fillColor: selectedMarker === index ? "red" : hasData ? "blue" : "grey"
                                        }}
                                        radius={selectedMarker === index ? calculateRadius(currentZoom) + 2 : calculateRadius(currentZoom)}
                                        fillOpacity={1}
                                        weight={0}
                                    >
                                    </CircleMarker>
                                );
                            })}
                            {selectedAddressLatLng &&
                                <CircleMarker
                                    center={selectedAddressLatLng}
                                    pathOptions={{ color: "red", fillColor: "red" }}
                                    fillOpacity={1}
                                    radius={6}
                                    weight={0}
                                />
                            }
                            <MapEventsHandler />
                        </MapContainer>
                    ) : (
                        <div>Loading map...</div>
                    )}
                    {showOverlayOpacity && (
                        <Box sx={{ position: 'absolute', bottom: 16, left: '50%', transform: 'translateX(-50%)', width: '40%', zIndex: 100000, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Paper elevation={2} sx={{ width: '100%', filter: openPopup === true ? 'grayscale(100) opacity(1) brightness(0.5)' : 'none', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Typography variant='h6'>Map Overlay Opacity</Typography>
                                <Slider
                                    value={overlayOpacity}
                                    min={0}
                                    max={1}
                                    step={0.01}
                                    onChange={handleOverlayOpacityChange}
                                    valueLabelDisplay="auto"
                                    sx={{ width: '85%' }}
                                />
                            </Paper>
                        </Box>
                    )}
                </Grid>

                {showPreferences && (
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{ backgroundColor: '#f5f5f5', overflowY: 'auto', position: 'relative' }}
                    >
                    <ErrorBoundary>
                        <Box sx={{ width:"100%", height: '100%', zIndex: 1000 }}>
                            <Preferences setShowPreferences={setShowPreferences} overlayLayers={overlayLayers} selectedLayers={selectedLayers} setSelectedLayers={setSelectedLayers} />
                        </Box>
                    </ErrorBoundary>
                    </Grid>
                )}
                {showContribution && (
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{ backgroundColor: '#f5f5f5', overflowY: 'auto', position: 'relative' }}
                    >
                        <ErrorBoundary>
                            <Box sx={{ width:"100%", height: '100%', zIndex: 1000 }}>
                                <ContributionComp
                                    setShowContribution={setShowContribution}
                                    selectedAddress={selectedAddress}
                                    setSelectMode={setSelectMode}
                                    setSelectedAddress={setSelectedAddress}
                                    clearSelectedAddress={() => {
                                        setSelectedAddress('');
                                        setSelectedAddressLatLng(null);
                                    }}
                                    refreshData={refreshData}
                                    year={year}
                                    setYear={setYear}
                                    setSelectedMarker={setSelectedMarker}
                                />
                            </Box>
                        </ErrorBoundary>
                    </Grid>
                )}
                {isSidePanelOpen && (
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{ backgroundColor: '#f5f5f5', overflowY: 'auto', position: 'relative' }}
                    >
                        <Box sx={{ width:"100%", height: '100%', zIndex: 1000 }}>
                                <SidePanelComponent
                                isSidePanelOpen={isSidePanelOpen}
                                setIsSidePanelOpen={setIsSidePanelOpen}
                                year={year}
                                setYear={setYear}
                                setSelectedMarker={setSelectedMarker}
                                setSelectedAddressLatLng={setSelectedAddressLatLng}
                                setShowContribution={setShowContribution}
                                setSelectedAddress={setSelectedAddress}
                                markerId={markerId}
                                />
                        </Box>
                    </Grid>
                )}
            </Grid>
        </Box>
    );

}

export default MapComponent;
