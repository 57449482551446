import React, {useEffect, useState} from 'react';
import './App.css';
import "@mui/material/styles";
import MapComponent from "./MapComponent";
import { UserPreferencesProvider } from './UserPreferencesContext';
import { AuthProvider } from "./authContext";
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import { ProjectProvider, useProject } from './ProjectContext';
import axios from 'axios';
import AdminDashboard from "./AdminDashboard";

function LoadProject({ children }) {
    const { city } = useParams();
    const { setProject } = useProject();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Fetch project details based on URL slug (city)
        axios.post('https://mapsite.whoseland.work/api/adminProjectsManager.php', {
            action: 'get',
            project_url_slug: city || 'default'
        }).then((response) => {
            if (response.data.success && Array.isArray(response.data.projects) && response.data.projects.length > 0) {
                setProject(response.data.projects[0]); // Extract the first project from the array
            } else {
                console.error('Project not found');
            }

            setLoading(false);
        }).catch(error => {
            console.error('Error loading project:', error);
            setLoading(false);
        });
    }, [city, setProject]);

    if (loading) return <div>Loading project...</div>;
    return children;
}

function App() {
    return (
        <AuthProvider>
            <UserPreferencesProvider>
                <ProjectProvider>
                    <Router>
                        <div className="App">
                            <Routes>
                                <Route path="/admin" element={<AdminDashboard />} />
                                <Route path="/:city" element={<LoadProject><MapComponent /></LoadProject>} />
                                <Route path="/" element={<LoadProject><MapComponent /></LoadProject>} />
                            </Routes>
                        </div>
                    </Router>
                </ProjectProvider>
            </UserPreferencesProvider>
        </AuthProvider>
    );
}

export default App;
