import React, { createContext, useContext, useState } from 'react';

const UserPreferencesContext = createContext();

export const UserPreferencesProvider = ({ children }) => {
    const [preferences, setPreferences] = useState(() => {
        const savedPreferences = localStorage.getItem('userPreferences');
        return savedPreferences ? JSON.parse(savedPreferences) : { baseLayer: 'JawgLight' };
    });

    return (
        <UserPreferencesContext.Provider value={{ preferences, setPreferences }}>
            {children}
        </UserPreferencesContext.Provider>
    );
};

export const useUserPreferences = () => useContext(UserPreferencesContext);
