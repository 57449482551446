import React, { useState, useEffect } from 'react';
import './decade_map.css'
import {
    Button,
    TextField,
    Chip,
    Stack,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    FormHelperText,
    Typography,
    TextareaAutosize, Box
} from '@mui/material';import {set} from "react-hook-form";
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import IconButton from "@mui/material/IconButton";
import { useAuth } from './authContext';
import {Close} from "@mui/icons-material"; // Import the auth context
import {useProject} from "./ProjectContext";


function ContributionComp(props) {
    const { user, userFirstName, userLastName, globalRoles } = useAuth(); // Get the current user from the context
    const { setShowContribution, selectedAddress, setSelectMode, setSelectedAddress, clearSelectedAddress, fetchDataForMarker, year, setYear, setSelectedMarker } = props;
    const { project } = useProject();
    const [formData, setFormData] = useState({
        year: props.year,
        address: props.selectedAddress,
        unit: "",
        placeName: "",
        occupant: "",
        otherOccupantDetails: "",
        storyOfPlace: "",
        artifacts: "",
        sources: "",
        notes: "",
        mgrs: "",
        uploadedFiles: "",
        fileIds: "",
        projectid: project ? project.projectid : "",

        contFirstName: user? userFirstName : "",
        contLastName: user? userLastName : "",
        contEmail: user ? user.email : "",
        contCons: "", // YesNo Contributor Consent
        contributorId: user ? user.uid : "",

    });
    const [error, setError] = useState({
        firstName: '',
        lastName: '',
        email: '',
        address: '',  // added address error state
    });    const [page, setPage] = useState(1);

    useEffect(() => {
        setFormData(prevFormData => ({
            ...prevFormData,
            address: props.selectedAddress
        }));

        setError(prevError => ({
            ...prevError,
            address: props.selectedAddress ? '' : 'address is required'
        }));
    }, [props.selectedAddress]);

    const handleChange = (event) => {
        const { name, value } = event.target;

        if (name === 'address') {
            props.setSelectedAddress(value);  // update selectedAddress when typing in the address field
        }

        // error handling
        setError(prev => ({
            ...prev,
            [name]: value ? "" : name + ' is required'
        }));

        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };
    const handleSubmit = async (event) => {
        event.preventDefault();

        // Prevent submission if there are new errors
        let newErrors = {};
        if (!user && !formData.contFirstName) newErrors.firstName = 'Name is required';
        if (!user && !formData.contLastName) newErrors.lastName = 'Name is required';
        if (!user && !formData.contEmail) newErrors.email = 'Email is required';
        if (!formData.address) newErrors.address = 'Address is required';
        if (!formData.year) newErrors.year = 'Year is required'

        if (Object.keys(newErrors).length > 0) {
            setError(newErrors);
            return;
        }

        // Upload files to Google Drive first
        try {
            const uploadedFilesData = await Promise.all(files.map(file => uploadFileToGoogleDrive(file)));
            const uploadedFiles = uploadedFilesData.map(data => data.webViewLink).join(', ');
            const fileIds = uploadedFilesData.map(data => data.id).join(', ');

            // Include uploadedFiles and fileIds in the formData for submission
            const submissionFormData = new URLSearchParams({
                ...formData,
                uploadedFiles: uploadedFiles,
                fileIds: fileIds,
                contributorId: user ? user.uid : `${formData.contFirstName}_${formData.contLastName}`,
            }).toString();

            const response = await fetch('https://mapsite.whoseland.work/api/submitData.php', {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: submissionFormData,
            });

            const data = await response.text();
            if (data === 'Records inserted successfully.') {
                alert('Thank you for your submission! It will be reviewed and added shortly.');
                props.setShowContribution(false);
                props.clearSelectedAddress();
                props.refreshData();
                // Consider resetting form and file state here
            } else {
                alert('An error occurred: ' + data);
            }
        } catch (error) {
            console.error('Error during file upload or form submission:', error);
            alert('Error during submission');
        }
    };

    const [files, setFiles] = useState([]);

    const handleFileChange = (event) => {
        // Add selected files to state, keeping File objects
        setFiles(prevFiles => [...prevFiles, ...Array.from(event.target.files)]);
    };

    const handleRemoveFile = (fileName) => {
        // Remove the file from the state by filtering it out
        setFiles(prevFiles => prevFiles.filter(file => file.name !== fileName));
    };
    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });




    async function uploadFileToGoogleDrive(file) {
        const response = await fetch('https://api.whoseland.org/api/accessTokenServer.js');
        const { accessToken } = await response.json();

        const formData = new FormData();
        formData.append('metadata', new Blob([JSON.stringify({
            'name': file.name,
            'mimeType': file.type,
            'parents': ['0AEqlxi4GS3plUk9PVA'] // Shared Drive ID
        })], {type: 'application/json'}));
        formData.append('file', file);

        const uploadResponse = await fetch('https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&supportsAllDrives=true', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            body: formData
        });

        const uploadData = await uploadResponse.json();
        if (!uploadResponse.ok) {
            throw new Error('File upload failed');
        }

        console.log('File uploaded successfully. File ID:', uploadData.id);
        const fileId = uploadData.id; // Use uploadData to get the fileId

        // Set file permissions to make it viewable
        await setFilePermissions(fileId, accessToken);

        // Get file details, including webViewLink
        const fileDetails = await getFileDetails(fileId, accessToken);



        console.log('WebViewLink:', fileDetails.webViewLink);
        return {
            id: uploadData.id,
            webViewLink: fileDetails.webViewLink // Assuming this information is retrieved correctly
        };

    }

    async function setFilePermissions(fileId, accessToken) {
        const permissions = {
            'type': 'anyone',
            'role': 'reader',
        };

        await fetch(`https://www.googleapis.com/drive/v3/files/${fileId}/permissions?supportsAllDrives=true`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(permissions),
        }).then(response => {
            if (!response.ok) {
                throw new Error(`Failed to set file permissions. Status: ${response.status}`);
            }
            return response.json();
        }).then(data => {
            console.log('Permissions set:', data);
        }).catch(error => {
            console.error('Error setting permissions:', error);
        });
    }

    async function getFileDetails(fileId, accessToken) {
        const response = await fetch(`https://www.googleapis.com/drive/v3/files/${fileId}?fields=webViewLink&supportsAllDrives=true`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
        });

        if (!response.ok) {
            throw new Error('Failed to get file details');
        }

        const fileData = await response.json();
        return fileData; // This will include webViewLink if available
    }
    const handleDropdownChange = (event) => {
        const newValue = event.target.value;
        handleChange(event);
        props.setYear(newValue);
    };

    function handleBack() {
        if (page === 2)
            setPage(1)
        else if (page === 1)
            setShowContribution(false)
    }


    return (
        <div style={{ position: 'absolute', right: 0, top: 0, width: '100%', height: '100%', overflowY: 'scroll', overflowX: 'hidden', background: '#f5f5f5', zIndex: 1000}}>
            <Box sx={{ position: 'absolute', top: 0, right: 0, zIndex: 10000 }}>
                <IconButton
                    aria-label="close"
                    onClick={() => {
                        setShowContribution(false);
                        clearSelectedAddress();
                        setSelectedMarker(null);
                    }}
                >
                    <Close />
                </IconButton>
            </Box>
            <form className='form-container' onSubmit={handleSubmit}>
                    <div className='form-container1'>
                        <Typography className='form-text'>Use the form below to contribute to the
                            project.</Typography>
                        <br/>
                        <Button variant="outlined" onClick={() => props.setSelectMode(true)}>Select on Map</Button>
                        <br/>
                        <Typography><strong>OR</strong></Typography>
                        <br/>
                        <Typography>Type an Address</Typography>
                        <TextField
                            label="Address"
                            type="text"
                            name="address"
                            value={formData.address}
                            onChange={handleChange}
                            className='form-input'
                            error={Boolean(error.address)}
                            helperText={error.address}
                        />
                        <br/>
                        <FormControl className='form-year'>
                        <InputLabel>Year</InputLabel>
                        <Select
                            label="YEAR"
                            name="year"
                            value={props.year}
                            defaultValue={props.year}
                            onChange={handleDropdownChange}
                            error={Boolean(error.year)}
                        >
                            <MenuItem value="">select</MenuItem>
                            <MenuItem value="1900">1900s</MenuItem>
                            <MenuItem value="1910">1910s</MenuItem>
                            <MenuItem value="1920">1920s</MenuItem>
                            <MenuItem value="1930">1930s</MenuItem>
                            <MenuItem value="1940">1940s</MenuItem>
                            <MenuItem value="1950">1950s</MenuItem>
                            <MenuItem value="1960">1960s</MenuItem>
                            <MenuItem value="1970">1970s</MenuItem>
                            <MenuItem value="1980">1980s</MenuItem>
                            <MenuItem value="1990">1990s</MenuItem>
                            <MenuItem value="2000">2000s</MenuItem>
                            <MenuItem value="2010">2010s</MenuItem>
                            <MenuItem value="2020">2020s</MenuItem>
                            <MenuItem value="2030">Vision</MenuItem>
                        </Select>
                        <FormHelperText>Please select the decade that best fits the information being
                            submitted.</FormHelperText>

                    </FormControl>
                        <br/>
                        <TextField
                            label="Place Name"
                            type="text"
                            name="placeName"
                            value={formData.placeName}
                            onChange={handleChange}
                            className='form-input'
                        />
                        <br/>
                        <TextField
                            label="Occupant"
                            type="text"
                            name="occupant"
                            value={formData.occupant}
                            onChange={handleChange}
                            className='form-input'
                        />
                        <br/>
                        <TextField
                            label="Occupant Details (Race/Ethnicity, Occupation, Etc.)"
                            multiline
                            rows={3}
                            type="text"
                            name="otherOccupantDetails"
                            value={formData.otherOccupantDetails}
                            onChange={handleChange}
                            className='form-input'
                        />
                        <br/>
                        <TextField
                            label="Story of Place"
                            multiline
                            rows={5}
                            type="text"
                            name="storyOfPlace"
                            value={formData.storyOfPlace}
                            onChange={handleChange}
                            className='form-input'
                        />
                        <br/>
                        <TextField
                            label="Associated Artifacts and Historical Materials"
                            multiline
                            rows={3}
                            type="text"
                            name="artifacts"
                            value={formData.artifacts}
                            onChange={handleChange}
                            className='form-input'
                        />
                        <br/>
                        <label htmlFor="raised-button-file">
                            <VisuallyHiddenInput
                                accept="image/*,audio/mp3,audio/*"
                                id="raised-button-file"
                                multiple
                                type="file"
                                onChange={handleFileChange}
                            />
                            <Button
                                component="span" // Use "span" here to correctly associate the label
                                variant="contained"
                                startIcon={<CloudUploadIcon/>}
                            >
                                Upload file
                            </Button>
                        </label>
                        <Stack direction="column" spacing={1} marginTop={2}>
                            {files.map((file, index) => (
                                <Chip
                                    key={index}
                                    label={file.name}
                                    onClick={() => {}}
                                    onDelete={() => handleRemoveFile(file.name)}
                                    color="primary"
                                    variant="outlined"
                                    // If you need an icon, uncomment the line below
                                    deleteIcon={<DeleteOutlineIcon/>}
                                />
                            ))}
                        </Stack>
                        <br/>
                        <TextField
                            label="Sources (Book, Census, Anecdote, Etc.)"
                            multiline
                            rows={3}
                            type="text"
                            name="sources"
                            value={formData.sources}
                            onChange={handleChange}
                            className='form-input'
                        />
                        <br/>
                        <TextField
                            label="Additional Notes"
                            multiline
                            rows={3}
                            type="text"
                            name="notes"
                            value={formData.notes}
                            onChange={handleChange}
                            className='form-input'
                        />
                        <br/>
                        <TextField
                            label="Project ID"
                            type="text"
                            name="projectid"
                            value={formData.projectid}
                            onChange={handleChange}
                            className='form-input'
                            disabled={!globalRoles.isAdmin}
                        />
                        {!user && (
                            <>
                                <br/>
                                <br/>
                                <br/>
                                <Typography><strong>Please fill in your information below.</strong></Typography>
                                <br/>
                                <TextField
                                    label="First Name"
                                    type="text"
                                    name="contFirstName"
                                    value={formData.contFirstName}
                                    onChange={handleChange}
                                    className='form-input'
                                    error={Boolean(error.firstName)}
                                    helperText={error.firstName}
                                />
                                <br/>
                                <br/>
                                <TextField
                                    label="Last Name"
                                    type="text"
                                    name="contLastName"
                                    value={formData.contLastName}
                                    onChange={handleChange}
                                    className='form-input'
                                    error={Boolean(error.lastName)}
                                    helperText={error.lastName}
                                />
                                <br/>
                                <br/>
                                <TextField
                                    label="Email"
                                    type="email"
                                    name="contEmail"
                                    value={formData.contEmail}
                                    onChange={handleChange}
                                    className='form-input'
                                    error={Boolean(error.email)}
                                    helperText={error.email}
                                />
                            </>
                        )}
                        <br/>
                        <Typography className='consent'>I consent to be contacted in the future in regards to the project.</Typography>
                        {error.address && <Typography className={'form-error'}>{error.address}</Typography>}
                        {error.year && <Typography className={'form-error'}>{error.year}</Typography>}
                        <div style={{padding: "1.5vh"}}><Button variant="contained" style={{padding: "1vh"}} type="submit">Submit</Button></div>
                    </div>
            </form>
        </div>
    );

}

export default ContributionComp;
