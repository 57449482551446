import React, { useState, useEffect } from 'react';
import { Grid, Box, Typography, List, ListItem, ListItemText, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const AdminHome = () => {
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    // Fetch projects from the API
    useEffect(() => {
        axios.get('https://mapsite.whoseland.work/api/fetchAllProjects.php')
            .then((response) => {
                if (response.data.success) {
                    setProjects(response.data.projects);
                } else {
                    setError('Failed to fetch projects');
                }
                setLoading(false);
            })
            .catch((err) => {
                setError('Error fetching projects');
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <Box display="flex" justifyContent="center" alignItems="center" height="100vh"><CircularProgress /></Box>;
    }

    if (error) {
        return <Typography variant="h6" color="error">{error}</Typography>;
    }

    return (
        <Box sx={{ padding: '20px' }}>
            <Typography variant="h4" gutterBottom align="center">
                Admin Dashboard - Projects
            </Typography>
            <Grid container spacing={3}>
                {projects && projects.length > 0 && projects
                    .filter(project => project.projectsid !== 10)
                    .map((project) => (
                    <Grid item xs={12} sm={6} lg={4} key={project.projectsid}>
                        <Box
                            sx={{
                                border: '1px solid #ddd',
                                borderRadius: '8px',
                                padding: '20px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
                                cursor: 'pointer',
                                '&:hover': { boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)' }
                            }}
                            onClick={() => navigate(`/${project.project_url_slug}`)} // Navigate to the project URL slug
                        >
                            {/* Project Name */}
                            <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign: 'center', marginBottom: '16px' }}>
                                {project.project_name}
                            </Typography>

                            {/* Logo */}
                            <Box sx={{ width: '250px', height: 'auto', marginBottom: '16px', alignSelf: 'center' }}>
                                <img
                                    src={project.logo_url}
                                    alt={`${project.project_name} logo`}
                                    style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                                />
                            </Box>

                            {/* Project Details */}
                            <List>
                                <ListItem>
                                    <ListItemText primary="Description" secondary={project.description} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Project ID" secondary={project.projectid} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="URL Address" secondary={project.project_url_slug} />
                                </ListItem>
                            </List>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default AdminHome;
